import styled from '@emotion/styled';
import { Col, Collapse, Form, Row, Spin, Tooltip } from 'antd';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import {
  useAddDocument,
  useAddNote,
  useEditNote,
  useGetLoan,
  useRemoveDocument,
  useRemoveNote,
  useSettleLoan,
  useUpdateLoan,
} from '../../../api/loanHooks';
import { useGetPeople } from '../../../api/peopleHooks';
import {
  AlertMessage,
  B,
  CustomDivider,
  Div,
  FooterComponent,
  PrimaryButton,
  SecondaryButton,
} from '../../../components';
import { getAllPeople } from '../../../store/modules/people';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import {
  LoanStatus,
  LoanTypeEnum,
  LoanUpdateI,
  UpdateUserLoanI,
} from '../../../types/loan.types';
import { getPathWithOrgId } from '../../../util/getPathWithOrgId';
import { numberThousandSeparator } from '../../../util/utils';
import { getLatestJob } from '../../people/PeopleUtil';
import LoanDetails from '../add-loan/LoanDetails';
import SelectEmployee from '../add-loan/SelectEmployee';
import NotesAndDocuments from '../shared/NotesAndDocuments';
import InstallmentTable from './InstallmentTable';
import LoanOverview from './LoanOverview';
import {
  loanInterestAmountCal,
  installmentAmountCal,
} from '../../../util/loanCalculations';
import { InfoSVG } from '../../../assets';
import dayjs from 'dayjs';

const { Panel } = Collapse;

const CollapseSection = styled(Collapse)`
  border: none;
  background-color: ${theme.white};
  .ant-collapse-header {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: ${theme.black};
    background: ${theme.gray50} !important;
    border-radius: 4px !important;
    border: 0.5px solid ${theme.gray300};
    padding: 16px 32px !important;
  }
  .ant-collapse-content {
    border-top: none;
    .ant-collapse-content-box {
      padding: 0px !important;
    }
    border: 1px solid ${theme.gray300};
    border-radius: 0px !important;
    margin-top: 4px;
  }
  .ant-collapse-item {
    padding: 0px !important;
    border-radius: 4px !important;
    border-bottom: none;
  }
  .ant-collapse-item-active {
    padding: 0px !important;
  }
  .ant-collapse-header .ant-collapse-arrow {
    top: 17px !important;
    left: 34px !important;
    font-weight: 900 !important;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    display: inline-block !important;
    margin-right: 12px !important;
    font-size: 12px !important;
  }
  .ant-collapse-arrow svg {
    margin-top: 6px;
  }
  .ant-collapse-item-active > .ant-collapse-header {
    border-radius: 4px 4px 0px 0px !important;
  }
`;

const LoanForm = styled(Form)`
  .ant-input-number-input[disabled],
  .ant-input[disabled],
  .ant-picker-input > input[disabled],
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: ${theme.black};
  }
`;

type ParamsI = {
  id: string;
};

const TWELVE_MONTHS = 12;

const ViewLoanPage: FC = () => {
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();
  const params = useParams<ParamsI>();
  const [form] = Form.useForm();
  const [releaseDate, setReleaseDate] = useState<string>();
  const [loanPeriod, setLoanPeriod] = useState<number>();
  const [loanAmount, setLoanAmount] = useState<number>();
  const [loanInterest, setLoanInterest] = useState<number>();
  const [guarantorList, setGuarantorList] = useState<
    {
      label: string;
      value: number;
    }[]
  >();
  const [isReleased, setIsReleased] = useState(false);
  const allPeoples = useSelector((state: RootState) => state.people.allPeoples);
  const {
    isLoading: loadingGetLoan,
    data: dataGetLoan,
    refetch: getLoan,
  } = useGetLoan(Number(params.id));

  const installments = dataGetLoan?.installment.filter((i) => i.amount > 0);

  const {
    isLoading: loadingUpdateLoan,
    isSuccess: successUpdateLoan,
    mutate: updateLoan,
  } = useUpdateLoan();

  const { data: peopleData, mutate: fetchPeople } = useGetPeople();
  const { isSuccess: successSettleLoan, mutate: settleLoan } = useSettleLoan();

  const { isSuccess: successAddNote, mutate: addNote } = useAddNote();
  const { isSuccess: successEditNote, mutate: editNote } = useEditNote();
  const { isSuccess: successRemoveNote, mutate: removeNote } = useRemoveNote();
  const { isSuccess: successAddDocument, mutate: addDocument } =
    useAddDocument();
  const { isSuccess: successRemoveDocument, mutate: removeDocument } =
    useRemoveDocument();

  let loanInterestAmount;
  let installmentAmount;
  if (dataGetLoan) {
    loanInterestAmount = loanInterestAmountCal(
      loanAmount ? loanAmount : dataGetLoan.loanAmount,
      loanInterest ? loanInterest : dataGetLoan.loanInterest,
    );

    installmentAmount = installmentAmountCal(
      loanAmount ? loanAmount : dataGetLoan.loanAmount,
      loanPeriod ? loanPeriod : dataGetLoan.loanPeriod,
      loanInterestAmount,
    );
  }

  const monthlySalary = peopleData?.calculation?.netSalary;
  
  const isNotValidLoan =
    dataGetLoan?.loanType === LoanTypeEnum.DEFAULT
      ? monthlySalary < installmentAmount
      : monthlySalary < (loanAmount ?? dataGetLoan?.loanAmount);
  

  useEffect(() => {
    if (peopleData) {
      form.setFieldsValue({
        netSalary: numberThousandSeparator(peopleData?.calculation.netSalary),
      });
    }
  }, [peopleData]);

  useEffect(() => {
    if (params.id) {
      getLoan();
    }
    dispatch(getAllPeople());
  }, []);

  useEffect(() => {
    if (isReleased && releaseDate) {
      form.setFieldsValue({
        releaseDate:dayjs(releaseDate),
      });
      onChangeReleaseDate(releaseDate);
    }
  }, [isReleased,releaseDate]);

  useEffect(() => {
    if (dataGetLoan) {
      form.setFieldsValue({
        userId: dataGetLoan.user.fullName,
        empNumber: dataGetLoan?.user.employeeNumber,
        designation: getLatestJob(dataGetLoan?.user.userHistory)?.designation,
        loanId: dataGetLoan.loanId,
        loanAmount: String(dataGetLoan.loanAmount),
        loanType: dataGetLoan.loanType,
        releaseDate: dayjs(dataGetLoan.releaseDate),
        loanPeriod: dataGetLoan.loanPeriod && dataGetLoan.loanPeriod,
        loanInterest: dataGetLoan.loanInterest
          ? String(dataGetLoan.loanInterest)
          : '0',
        maturityDate:
          dataGetLoan.maturityDate && dayjs(dataGetLoan.maturityDate),
        installmentAmount:
          dataGetLoan.installmentAmount &&
          Number(dataGetLoan.installmentAmount).toFixed(2),
        noOfInstallments:
          dataGetLoan.noOfInstallments && dataGetLoan.noOfInstallments,
        guarantorId: dataGetLoan.guarantor && dataGetLoan.guarantorId,
        isInternalGuarantor: dataGetLoan.isInternalGuarantor,
        guarantorName:dataGetLoan.guarantorName,
        guarantorContactNumber:
          dataGetLoan.guarantorContactNumber,
        guarantorEmail:dataGetLoan.guarantorEmail,
      });
      setIsReleased(dataGetLoan.loanReleasedToEmployee);
    }

    if (dataGetLoan?.userId) {
      fetchPeople(String(dataGetLoan.userId));
    }
  }, [dataGetLoan]);

  useEffect(() => {
    if (allPeoples && allPeoples.length) {
      setGuarantorList(
        allPeoples
          .sort((a, b) => a.fullName?.localeCompare(b.fullName))
          .filter(
            (active) => !active.isArchived && active.id !== dataGetLoan?.userId,
          )
          .map((item) => ({
            label: `${item.fullName} ${
              item.employeeNumber ? `(${item.employeeNumber})` : ''
            }`,
            value: item.id,
          })),
      );
    } else {
      setGuarantorList([]);
    }
  }, [dataGetLoan?.userId, allPeoples]);

  useEffect(() => {
    if (
      successUpdateLoan ||
      successAddNote ||
      successRemoveNote ||
      successEditNote ||
      successAddDocument ||
      successRemoveDocument ||
      successSettleLoan
    ) {
      getLoan();
    }
  }, [
    successUpdateLoan,
    successAddNote,
    successRemoveNote,
    successEditNote,
    successAddDocument,
    successRemoveDocument,
    successSettleLoan,
  ]);

  const onChangeLoanAmount = (loanAmount: number) => {
    if (loanAmount && dataGetLoan) {
      const period = loanPeriod ? loanPeriod : dataGetLoan.loanPeriod;
      const InterestRate = loanInterest
        ? loanInterest
        : loanInterest === 0
        ? 0
        : dataGetLoan.loanInterest;
      const loanInterestAmount =
        (loanAmount * (InterestRate / 100)) / TWELVE_MONTHS;
      const installmentAmount = loanAmount / period + loanInterestAmount;
      form.setFieldsValue({
        installmentAmount: installmentAmount.toFixed(2),
      });
    }
  };

  const onChangeLoanInterest = (loanInterest: number) => {
    if (loanInterest && dataGetLoan) {
      const amount = loanAmount ? loanAmount : dataGetLoan.loanAmount;
      const period = loanPeriod ? loanPeriod : dataGetLoan.loanPeriod;
      const loanInterestAmount =
        (amount * (loanInterest / 100)) / TWELVE_MONTHS;
      const installmentAmount = amount / period + loanInterestAmount;
      form.setFieldsValue({
        installmentAmount: installmentAmount.toFixed(2),
      });
    }

    if (!loanInterest && dataGetLoan) {
      const amount = loanAmount ? loanAmount : dataGetLoan.loanAmount;
      const period = loanPeriod ? loanPeriod : dataGetLoan.loanPeriod;
      const installmentAmount = amount / period;
      form.setFieldsValue({
        installmentAmount: installmentAmount.toFixed(2),
      });
    }
  };

  const onChangeLoanPeriod = (loanPeriod: number) => {
    if (loanPeriod && dataGetLoan) {
      const releasingDate = releaseDate ? releaseDate : dataGetLoan.releaseDate;
      const maturityDate = moment(releasingDate)
        .add(loanPeriod, 'months')
        .calendar();
      const amount = loanAmount ? loanAmount : dataGetLoan.loanAmount;
      const InterestRate = loanInterest
        ? loanInterest
        : loanInterest === 0
        ? 0
        : dataGetLoan.loanInterest;
      const loanInterestAmount =
        (amount * (InterestRate / 100)) / TWELVE_MONTHS;
      const installmentAmount = amount / loanPeriod + loanInterestAmount;
      form.setFieldsValue({
        noOfInstallments: loanPeriod,
        maturityDate: dayjs(maturityDate),
        installmentAmount: installmentAmount.toFixed(2),
      });
    }
  };

  const onChangeReleaseDate = (releaseDate: string) => {
    if (releaseDate && dataGetLoan) {
      const period = loanPeriod ? loanPeriod : dataGetLoan.loanPeriod;
      const maturityDate = moment(releaseDate).add(period, 'months').calendar();
      form.setFieldsValue({
        maturityDate: dayjs(maturityDate),
      });
    }
  };

  const onFinish = async (values: LoanUpdateI) => {
    const value: LoanUpdateI = {
      releaseDate: dayjs(values.releaseDate).format('YYYY-MM-DD'),
      loanAmount: Number(values.loanAmount),
      loanPeriod: Number(values.loanPeriod),
      loanInterest: values.loanInterest ? Number(values.loanInterest) : 0,
      maturityDate: dayjs(values.maturityDate).format('YYYY-MM-DD'),
      installmentAmount: Number(values.installmentAmount),
      noOfInstallments: values.noOfInstallments,
      loanReleasedToEmployee: isReleased,
      ...(values.guarantorName && { guarantorName: values.guarantorName }),
      ...(values.guarantorEmail && { guarantorEmail: values.guarantorEmail }),
      ...(values.guarantorContactNumber && {
        guarantorContactNumber: values.guarantorContactNumber,
      }),
      ...(values.guarantorId && { guarantorId: values.guarantorId }),
      ...(dataGetLoan.loanType === LoanTypeEnum.DEFAULT && {
        isInternalGuarantor: values.isInternalGuarantor,
      }),
    };

    if (params.id) {
      const dataSet: UpdateUserLoanI = {
        id: Number(params.id),
        data: value,
      };
      await updateLoan(dataSet);
    }
  };

  const refreshLoanData = () => {
    getLoan();
  };

  return (
    <div>
      <Spin spinning={loadingGetLoan}>
        <Div p="32px 40px 0px 48px">
          <LoanOverview
            loandata={dataGetLoan}
            netSalary={peopleData?.calculation?.netSalary}
            onSubmit={(values) => {
              if (params.id) {
                const v = {
                  id: Number(params.id),
                  data: values,
                };
                settleLoan(v);
              }
            }}
          />
          <CustomDivider
            mt={24}
            mb={0}
            borderTop={`1px solid ${theme.gray300}`}
          />
        </Div>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={19}>
            <LoanForm form={form} onFinish={(v) => onFinish(v as LoanUpdateI)}>
              <Div p="32px 40px 32px 48px">
                <CollapseSection>
                  <Panel
                    header={
                      <B type="b-large" pl="32px">
                        Employee Details
                      </B>
                    }
                    key="1">
                    <Div p="24px 32px">
                      <SelectEmployee disableFields />
                    </Div>
                  </Panel>
                </CollapseSection>
              </Div>
              <CustomDivider m={0} borderTop={`0.5px solid ${theme.gray300}`} />
              <Div p="32px 40px 32px 48px">
                <B type="b-large-semibold" pb="32px">
                  Employee Loan Details
                </B>
                <LoanDetails
                  isDraftLoan={dataGetLoan?.loanStatus === LoanStatus.DRAFT}
                  isLoanView={true}
                  loanDetails={dataGetLoan}
                  userNetSalary={peopleData?.calculation.netSalary}
                  disableFields={dataGetLoan?.loanStatus != LoanStatus.DRAFT}
                  onChangeLoanAmount={(e) => {
                    onChangeLoanAmount(Number(e));
                    setLoanAmount(Number(e));
                  }}
                  onChangeLoanPeriod={(e) => {
                    onChangeLoanPeriod(Number(e));
                    setLoanPeriod(Number(e));
                  }}
                  onChangeReleaseDate={(e) => {
                    onChangeReleaseDate(String(e));
                    setReleaseDate(String(e));
                  }}
                  onChangeLoanInterest={(e) => {
                    onChangeLoanInterest(Number(e.target.value));
                    setLoanInterest(Number(e.target.value));
                  }}
                  GuarantorList={guarantorList ? guarantorList : []}
                  internal={dataGetLoan?.isInternalGuarantor}
                />
                {isNotValidLoan && (
                  <Div Pb="16px">
                    <AlertMessage
                      type="error"
                      title="Loan instalment size is larger than the last drawn net salary, please adjust accordingly "></AlertMessage>
                  </Div>
                )}
                <Div display="flex" pb="12px" pt="8px">
                  <B type="b-large-semibold" pr="6px">
                    Installment
                  </B>
                  {dataGetLoan?.loanStatus === LoanStatus.DRAFT && (
                    <Tooltip
                      placement="right"
                      title="Please save your edits to refresh your installment plan">
                      <img src={InfoSVG} width="15px" />
                    </Tooltip>
                  )}
                </Div>
                <InstallmentTable
                  data={installments}
                  isLoadingdata={loadingGetLoan}
                />
              </Div>
              <CustomDivider m={0} borderTop={`0.5px solid ${theme.gray300}`} />
              <Div p="32px 40px 62px  48px">
                <NotesAndDocuments
                  isViewLoan={true}
                  note={dataGetLoan && dataGetLoan.notes}
                  userId={dataGetLoan && dataGetLoan.userId}
                  onStatusChange={refreshLoanData}
                  documents={dataGetLoan && dataGetLoan.document}
                  onAddNewNote={(values) => {
                    const v = {
                      loanId: dataGetLoan?.id,
                      ...values,
                    };

                    addNote(v);
                  }}
                  onRemoveNote={(id) => {
                    const selectedNote =
                      dataGetLoan &&
                      dataGetLoan.notes.find((note) => note.id === id);
                    if (selectedNote) {
                      removeNote(Number(selectedNote.id));
                    }
                  }}
                  onEditNote={(id, value) => {
                    const selectedNote =
                      dataGetLoan &&
                      dataGetLoan.notes.find((note) => note.id === id);
                    if (selectedNote) {
                      editNote({
                        id: Number(selectedNote.id),
                        note: value.note,
                        reminderDate: value.reminderDate,
                        loanId: dataGetLoan?.loanId,
                      });
                    }
                  }}
                  onSubmitDocument={(values) => {
                    const v = {
                      loanId: dataGetLoan?.id,
                      ...values,
                    };
                    addDocument(v);
                  }}
                  onRemoveDocument={(id) => {
                    const selectedDocument =
                      dataGetLoan &&
                      dataGetLoan.document.find(
                        (document) => document.id === id,
                      );
                    if (selectedDocument) {
                      removeDocument(Number(selectedDocument.id));
                    }
                  }}
                />
              </Div>
              <FooterComponent
                leftChildren={
                  <SecondaryButton
                    marginLeft={23}
                    onClick={() => navigate(getPathWithOrgId('/loans'))}>
                    Back
                  </SecondaryButton>
                }
                rightChildren={
                  dataGetLoan?.loanStatus === LoanStatus.DRAFT && (
                    <Row gutter={10} justify="end">
                      <SecondaryButton
                        ml={10}
                        htmlType="submit"
                        onClick={() => {
                          setIsReleased(true);
                          setReleaseDate(dayjs().format('YYYY-MM-DD'));
                        }}
                        disabled={isNotValidLoan}
                        loading={loadingGetLoan || loadingUpdateLoan}>
                        Release
                      </SecondaryButton>
                      <PrimaryButton
                        ml={10}
                        htmlType="submit"
                        disabled={isNotValidLoan}
                        loading={loadingGetLoan || loadingUpdateLoan}>
                        Save
                      </PrimaryButton>
                    </Row>
                  )
                }
              />
            </LoanForm>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default ViewLoanPage;
