import { message } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { AttendanceSettings } from '../types/attendance.types';
import { UpcomingEventsI } from '../types/notification.types';
import {
  CustomLeaveDeleteI,
  OrganizationBodyI,
  SystemUpdateMessagesI,
  UpdateOrganizationI,
} from '../types/organization.types';
import { getApiError } from '../util/getApiError';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducer';

export const useUpdateOrganization = () => {
  return useMutation<string, AxiosError, UpdateOrganizationI>(
    async (data) => {
      const response: AxiosResponse<string> = await axios.put(
        `/organization`,
        data,
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
      onSuccess: () => {
        message.success('Organization information updated successfully.');
      },
    },
  );
};

export const useGetMessages = () => {
  return useQuery<SystemUpdateMessagesI[], AxiosError>(
    'systemUpdateMessages',
    async () => {
      const response: AxiosResponse<SystemUpdateMessagesI[]> = await axios.get(
        '/organization/systemUpdateMessages',
      );
      return response.data;
    },
    {
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};
export const useGetUpcomingEvents = () => {
  return useMutation<UpcomingEventsI[], AxiosError>(
    'upcomingEvents',
    async () => {
      const response: AxiosResponse<UpcomingEventsI[]> = await axios.get(
        `organization/getUpcomingEvents`,
      );
      return response.data;
    },
  );
};

export const useUpdateContextToken = () => {
  return useMutation<string, AxiosError, string>(
    async (contextToken) => {
      const response: AxiosResponse = await axios.put(
        `/organization/team-configuration/contextToken`,
        {
          contextToken,
        },
      );
      return response.data;
    },
    {
      onSuccess: () => {
        message.success('Team app authorized successfully');
      },
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};

export const updateTimeAttendanceSettings = () => {
  return useMutation<string, AxiosError, AttendanceSettings>(
    async (data: AttendanceSettings) => {
      const response: AxiosResponse = await axios.put(
        `/organization/time-attendance/settings`,
        data,
      );
      return response.data;
    },
  );
};

export const useGetOrganization = () => {
  return useMutation<OrganizationBodyI, AxiosError>(async () => {
    const response: AxiosResponse = await axios.get('/organization');
    return response.data;
  });
};

export const useHoursPerDay = () => {
  const organizationData = useSelector(
    (state: RootState) => state.organization?.organizationData
  );
  return {HOURS_PER_DAY : organizationData?.dailyWorkingHours}; 
};

export const useShortLeaveHoursPerDay = () => {
  const organizationData = useSelector(
    (state: RootState) => state.organization?.organizationData
  );
  return {SHORT_LEAVE_HOURS_PER_DAY : organizationData?.shortLeaves?.limitPerDay}; 
};

export const useShortLeavesPerMonth= () => {
  const organizationData = useSelector(
    (state: RootState) => state.organization?.organizationData
  );
  return {SHORT_LEAVES_PER_MONTH : organizationData?.shortLeaves?.limitPerMonth}; 
};

export const useDeleteCustomLeaveBalance = () => {
  return useMutation<{ deletedId: string }, AxiosError, CustomLeaveDeleteI>(
    async (data: CustomLeaveDeleteI) => {
      const response: AxiosResponse<{ deletedId: string }> = await axios.delete(
        `/organization/customLeaveBalance`,
        { data },
      );
      return response.data;
    },
    {
      onSuccess: () => {
        message.success('Custom leaves deleted');
      },
      onError: (error) => {
        message.error(getApiError(error));
      },
    },
  );
};
