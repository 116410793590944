import React, { FC, useEffect, useState } from 'react';
import { Alert, Col, Row, Select, Tooltip } from 'antd';
import moment, { Moment } from 'moment';
import { B, Div, SelectComp } from '../../../components';
import { LeaveViewI } from '../../../types';
import { addDotsForLongText } from '../../../util/utils';
import { useOrganizationData } from '../../../custom-hooks/organizationHooks';
import theme from '../../../theme';
import styled from '@emotion/styled';
import { InfoSVG } from '../../../assets';

import { GrayCancel } from '../../../assets';


const CloseIcon = styled.img`
  padding-top: 12px;
  margin-left: 20px;
`;

interface Props {
  leave: LeaveViewI;
  onChangeFromTime?: (value: Moment) => void;
  onChangeToTime?: (value: Moment) => void;
  onChangeHours?: (value: any) => void;
  disabledHours?: () => number[];
  isShortLeave?: boolean;
}

const RowContainer = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid #e0e0e0;

  @media (max-width: 768px) {
    display: none;
  }
  @media (min-width: 480px) {
    width: 100%;
  }
`;
const NoOfHoursRowContainer = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 0.5px solid #bdbdbd;
  @media (min-width: 769px) {
    display: none;
  }
  @media (min-width: 480px) {
    width: 100%;
  }
`;

const Image = styled.img`
  width: 16px;
  height: 16px;
  margin-bottom: -15px;
  color: ${theme.red300};
`;
const LeaveSelector: FC<Props> = ({
  leave,
  onChangeHours,
  isShortLeave = false,
}) => {
  const { date, isHoliday, description, alreadyTaken, hours } = leave;

  const organizationalData = useOrganizationData();
  const SHORT_LEAVE_HOURS_PER_DAY = organizationalData?.shortLeaves?.limitPerDay || 0;

  const [selectedHours, setSelectedHours] = useState<number>(
    isShortLeave
      ? SHORT_LEAVE_HOURS_PER_DAY 
      : Number(organizationalData?.dailyWorkingHours),
  );

  const [noOfHours, setNoOfHours] = useState<string[]>();

  const { Option } = Select;

  const options: string[] = [];

  useEffect(() => {
    const maxHours = isShortLeave
      ? SHORT_LEAVE_HOURS_PER_DAY
      : Number(organizationalData?.dailyWorkingHours);

    const calculatedHours = hours || maxHours;
    setSelectedHours(calculatedHours);

    // Generate an array of time increment options
    for (let i = 0.5; i <= maxHours; i += 0.5) {
      options.push(i.toString()); // Convert to string with one decimal place
    }

    if (isShortLeave) {
      setNoOfHours([SHORT_LEAVE_HOURS_PER_DAY.toString()]); // for short leaves there is no options
      setSelectedHours(SHORT_LEAVE_HOURS_PER_DAY);
    } else {
      setNoOfHours(options);
      setSelectedHours(Number(organizationalData?.dailyWorkingHours));
    }
  }, [isShortLeave, organizationalData?.dailyWorkingHours]);


  return (
    <>
      <RowContainer style={{ backgroundColor: isHoliday && theme.gray50 }}>
        <Col
          xs={4}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          xxl={4}
          style={{ backgroundColor: theme.gray50 }}>
          <Div p="10px 13px">
            <B type="b-default">{moment(date).format('D MMM')}</B>
          </Div>
        </Col>
        <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}>
          {alreadyTaken !== null && (
            <Div p="11px -40px">
              <Tooltip
                title={alreadyTaken}
                trigger="hover"
                arrow={true}
                placement="right">
                <B type="b-small" color={theme.gray600}>
                  <Image src={InfoSVG} />
                </B>
              </Tooltip>
            </Div>
          )}
        </Col>

        <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
          <Div p="10px 30px">
            {isHoliday && !alreadyTaken && (
              <B type="b-default" color={theme.gray600}>
                {description ? addDotsForLongText(description, 15) : '-'}
              </B>
            )}
          </Div>
        </Col>
        <Col xs={8} sm={5} md={5} lg={5} xl={5} xxl={5}>
          <Div p="10px " display="flex" alignItems="left">
            <B type="b-default" color={isHoliday ? theme.gray600 : theme.black}>
              No of Hours
            </B>
          </Div>
        </Col>
        <Col xs={5} sm={5} md={3} lg={3} xl={3} xxl={3}>
          <Div p="10px 0px" display="flex" borderLeft="none">
            <SelectComp
              size="small"
              showSearch
              style={{ width: '74px' }}
              disabled={isHoliday}
              placeholder="hours"
              value={selectedHours}
              onChange={(value) => {
                setSelectedHours(Number(value));
                onChangeHours?.(value);
              }}>
              {noOfHours?.map((noOfHour) => (
                <Option key={noOfHour} value={noOfHour}>
                  {noOfHour}
                </Option>
              ))}
            </SelectComp>
          </Div>
        </Col>
      </RowContainer>

      <NoOfHoursRowContainer justify="space-between" align="middle">
        <Col xs={7} sm={6} md={7} lg={7} xl={4} xxl={4}>
          <Div p="10px 13px">
            <B type="b-default">{moment(date).format('D MMM')}</B>
          </Div>
        </Col>
        <Col xs={8} sm={5} md={5} lg={5} xl={5} xxl={5}>
          <Div p="10px " display="flex" alignItems="left">
            <B type="b-default" color={isHoliday ? theme.gray600 : theme.black}>
              No of Hours
            </B>
          </Div>
        </Col>
        <Col xs={5} sm={5} md={3} lg={3} xl={3} xxl={3}>
          <Div p="10px 0px" display="flex" borderLeft="none">
            <SelectComp
              size="small"
              showSearch
              style={{ width: '74px' }}
              disabled={isHoliday}
              placeholder="hours"
              value={selectedHours}
              onChange={(value) => {
                onChangeHours?.(value);
                setSelectedHours(Number(value));
              }}>
              {noOfHours?.map((noOfHour) => (
                <Option key={noOfHour} value={noOfHour}>
                  {noOfHour}
                </Option>
              ))}
            </SelectComp>
          </Div>
        </Col>
        {/* <Col xs={2} sm={2} md={2} lg={1} xl={1} xxl={1}>
          {showLeaveClose && (
            <img onClick={() => leaveClose(leave)} src={GrayCancel} />
          )}
        </Col> */}
      </NoOfHoursRowContainer>
    </>
  );
};
export default LeaveSelector;
