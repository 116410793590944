import styled from '@emotion/styled';
import { Col, Divider, Row } from 'antd';
import React, { FC } from 'react';
import { B, Div } from '../../../components';
import { disableSickleavesOrg } from '../../../configs/appConfig';
import theme from '../../../theme';
import { LeaveBalance, LeaveNameEnum, LeaveTypesEnum,  UserLeaveI } from '../../../types';
import { getCurrentAuthPayload } from '../../../util/storageUtil';
import {
  getHoursDaysFromHours,
  useGetLeaveStatus,
  getLeaveBalance,
  DEFAULT_LEAVE_LIST,
} from '../utils';
import getNormalText from '../../../util/getNormalText';
import HoursDaysFromHoursComponent from '../new-leave/HoursDaysFromHoursComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';
import dayjs from 'dayjs';

const LeaveDetailsSection = styled.div`
  background: rgba(51, 117, 238, 0.1);
  border-radius: 4px;
  padding: 0px !important;
  background-color: rgba(51, 117, 238, 0.1) !important;
  border: 1px solid ${theme.gray300};
  margin: 0px 0px 16px !important;
  font-family: Inter !important;

 
`;
const RowContainer = styled(Row)`
  margin-bottom: 14px;
`;

const StyledRow = styled(Row)`
  border-bottom: 1px solid ${theme.gray400} !important;
  padding: 8px 16px !important;

  &:last-child {
    border-bottom: none !important;
  }
`;
interface Props {
  leaveSummery: LeaveBalance[] | null;
  type?: string | null;
  month?: string | null;
  shortLeavesUser?: UserLeaveI[] | null;
}

const RowItem = ({ title, value , isShortLeave = false, month=dayjs().format('MMM')}: { value: number; title: string, isShortLeave?: boolean, month?: string }) => {
  const customTtitle = isShortLeave ? `${title} (${month})`  : title ;
  return (
    <StyledRow
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '10px 26px ',
      }}>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <B type="b-small" color={theme.blue800}>
          {customTtitle } 
        </B>
      </Col>
      <Col xs={12} sm={12} md={12} lg={12} xl={12}  style={{ alignItems: 'left' }}>
        <B type="b-small" color={theme.blue800} textAlign="left">
          {/* {value !== 0 ? getHoursDaysFromHours(value).text : '-'} */}
          {value !== 0 ? <HoursDaysFromHoursComponent hours={value} isShortLeave={isShortLeave} /> : '-'}

        </B>
      </Col>
    </StyledRow>
  );
};

const LeaveDetails: FC<Props> = ({ leaveSummery = [], type = null , shortLeavesUser = [], month=dayjs().format('MMM')}) => {
  const leaveStatus = useGetLeaveStatus(leaveSummery || []);
  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );
  
  const customLeaveTypes = organizationData?.customLeaveTypes;
  const SHORT_LEAVES_PER_MONTH = organizationData?.shortLeaves?.limitPerMonth || 0;
  const SHORT_LEAVE_HOURS_PER_DAY = organizationData?.shortLeaves?.limitPerDay || 0;
  
  const isShortLeaveExist = leaveSummery?.some(sumery => sumery.leaveName === LeaveNameEnum.SHORT_LEAVE)
  const shortMonthlyLeaveBalance = (SHORT_LEAVE_HOURS_PER_DAY * SHORT_LEAVES_PER_MONTH) - (shortLeavesUser.length * SHORT_LEAVE_HOURS_PER_DAY);

  const customLeaves = leaveSummery?.filter(
    (item) =>
      item.leaveType === LeaveTypesEnum.CUSTOM &&
      customLeaveTypes?.some((balance) =>
        balance.isEnable && balance.customLeaveName === item.leaveName
      )
  );

  return type ? (
    <RowContainer>
      <B type="b-small" color={theme.gray600}>
        {getLeaveBalance(type, leaveStatus)}
      </B>
    </RowContainer>
  ) : (
    <>
      <LeaveDetailsSection>
        <StyledRow style={{ padding: '0 16px ' }}>
          <B type="b-small-semibold" fontFamily="Inter">
            Leave Balance
          </B>
          {/* <Divider style={{  borderColor: theme.gray400}} /> */}
        </StyledRow>
        <Row gutter={[26, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <RowItem title="Annual Leave" value={leaveStatus.annualLeaves} />
            <RowItem title="Casual Leave" value={leaveStatus.casualLeaves} />
            {(isShortLeaveExist && SHORT_LEAVES_PER_MONTH > 0)&& (<RowItem title="Short Leave" value={shortMonthlyLeaveBalance}  isShortLeave={true} month={month}/>)}
            {/* FIXME */}
            {getCurrentAuthPayload()?.organizationId !==
            disableSickleavesOrg ? (
              <RowItem title="Sick Leave" value={leaveStatus.sickLeaves} />
            ) : null}{' '}
            {leaveStatus.noPay ? (
              <RowItem title="No Pay Leave" value={leaveStatus.noPay} />
            ) : null}
            {/* {leaveStatus.studyLeave ? (
              <RowItem title="Study Leave" value={leaveStatus.studyLeave} />
            ) : null}
            {leaveStatus.trainingLeave ? (
              <RowItem
                title="Training Leave"
                value={leaveStatus.trainingLeave}
              />
            ) : null}
            {leaveStatus.workOnHoliday ? (
              <RowItem
                title="Work On Holiday"
                value={leaveStatus.workOnHoliday}
              />
            ) : null}
            {leaveStatus.maternityLeave ? (
              <RowItem
                title="Maternity Leave"
                value={leaveStatus.maternityLeave}
              />
            ) : null}
            {leaveStatus.lieuLeave ? (
              <RowItem title="Lieu Leave" value={leaveStatus.lieuLeave} />
            ) : null} */}

            {customLeaves?.map((item) => {
              return (
                <RowItem
                  title={item.leaveName}
                  value={
                    leaveStatus[item.leaveName] > 0
                      ? leaveStatus[item.leaveName]
                      : '-'
                  }
                />
              );
            })}
          </Col>
        </Row>
      </LeaveDetailsSection>
    </>
  );
};

export default LeaveDetails;
