import React, { FC, useState } from 'react';
import { Card, Col, Row, Tooltip } from 'antd';
import LeavesBalance from './LeavesBalance';
import { PrimaryButton, B, CustomDivider } from '../../../components';
import { LeaveBalance, LeaveTypesEnum } from '../../../types';
import ApplyLeaveModal from './ApplyLeaveModal';
import { useNavigate } from 'react-router';
import { useGetLeaveStatus } from '../utils/leave.util';
import styled from '@emotion/styled';
import { PeopleI } from '../../../types/people.types';
import theme from '../../../theme';
import { getCurrentAuthPayload } from '../../../util/storageUtil';
import { disableSickleavesOrg } from '../../../configs/appConfig';
import LeaveBalanceComponent from './LeaveBalanceComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducer';
import { LogTrailSVG } from '../../../assets';


interface Props {
  leaveData: LeaveBalance[];
  allPeoples: PeopleI[];
  userId: number | undefined;
}
interface LeaveListProps {
  isShort?: boolean;
}

const LeaveOverviewDiv = styled.div`
  padding: 0px 0px 16px 0px;
  margin-top: -20px;
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;
const RequestLeaveButton = styled.div`
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;
const OverviewLeave = styled.div`
  padding: 24px 0px 0px 0px;
  @media only screen and (min-width: 768px) {
    display: none;
  }
`;
const LeaveOverviewListDiv = styled.div`
  margin: 24px 0px 16px 0px;
  @media only screen and (max-width: 400px) {
    display: none;
  }
`;
const FooterComp = styled.div`
  margin-left: auto;
  margin-right: 35px;
  width: 130px;
  margin-top: -20px;
  display: flex; 
  align-items: center;
`;
const StyledImage = styled.img`
  margin-left: 8px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  @media only screen and (max-width: 767px) {
    width: 36px;
    height: 36px;
    margin-left: 0px;
  }
`;

const LeaveOverview: FC<Props> = ({ leaveData = [], userId }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const leaveStatus = useGetLeaveStatus(leaveData || []);

  const organizationData = useSelector(
    (state: RootState) => state.organization.organizationData,
  );

  const customLeaveTypes = organizationData?.customLeaveTypes;

  const customLeaves =
    leaveData?.filter(
      (item) =>
        item.leaveType === LeaveTypesEnum.CUSTOM &&
        customLeaveTypes?.some(
          (balance) =>
            balance.isEnable && balance.customLeaveName === item.leaveName,
        ),
    ) || [];

  const LeaveOverviewList: FC<LeaveListProps> = ({ isShort = false }) => (
    <Card>
      <LeavesBalance
        leaveTitle="Annual Leave Balance"
        leavesBalance={leaveStatus.annualLeaves}
        isMobile
        isShort={isShort}
      />
      <LeavesBalance
        leaveTitle="Sick Leave Balance"
        leavesBalance={leaveStatus.sickLeaves}
        isMobile
        isShort={isShort}
      />
      <LeavesBalance
        leaveTitle="Casual Leave Balance"
        leavesBalance={leaveStatus.casualLeaves}
        isMobile
        isShort={isShort}
      />
      <LeavesBalance
        leaveTitle="Short Leave Balance"
        leavesBalance={leaveStatus.shortLeaves}
        isMobile
        isShort={isShort}
        isShortLeave={true}
      />
      {customLeaves?.map((item) => {
        return (
          <LeavesBalance
            leaveTitle={item.leaveName}
            leavesBalance={leaveStatus[item.leaveName]}
            isMobile
            isShort={isShort}
          />
        );
      })}
      {leaveStatus.noPay ? (
        <LeavesBalance
          leaveTitle="No Pay Leave Balance"
          leavesBalance={leaveStatus.noPay}
          isMobile
          isShort={isShort}
        />
      ) : null}
      {/* {leaveStatus.studyLeave ? (
        <LeavesBalance
          leaveTitle="Study Leave Balance"
          leavesBalance={leaveStatus.studyLeave}
          isMobile
          isShort={isShort}
        />
      ) : null}
      {leaveStatus.trainingLeave ? (
        <LeavesBalance
          leaveTitle="Training Leave Balance"
          leavesBalance={leaveStatus.trainingLeave}
          isMobile
          isShort={isShort}
        />
      ) : null}
      {leaveStatus.maternityLeave ? (
        <LeavesBalance
          leaveTitle="Maternity Leave Balance"
          leavesBalance={leaveStatus.trainingLeave}
          isMobile
          isShort={isShort}
        />
      ) : null}
      {leaveStatus.workOnHoliday ? (
        <LeavesBalance
          leaveTitle="Work On Holiday"
          leavesBalance={leaveStatus.workOnHoliday}
          isMobile
          isShort={isShort}
        />
      ) : null}
      {leaveStatus.lieuLeave ? (
        <LeavesBalance
          leaveTitle="Lieu Leave"
          leavesBalance={leaveStatus.lieuLeave}
          isMobile
          isShort={isShort}
        />
      ) : null} */}
    </Card>
  );

  return (
    <>
      <OverviewLeave>
        <Row justify="space-between" align="middle">
          <Col xs={10}>
            <B type="b-large-semibold"> Leave </B>
          </Col>
          <Col>
            <RequestLeaveButton>
              <PrimaryButton
                htmlType="submit"
                onClick={() => navigate('/leave/apply')}>
                Request Leave
              </PrimaryButton>
            </RequestLeaveButton>
          </Col>
          <Col>
            <Tooltip title="View Log Trail" placement="bottomRight">
              <StyledImage
                className="ant-dropdown-link"
                onClick={() => navigate('/leave/log-trail')}
                src={LogTrailSVG}
              />
            </Tooltip>
          </Col>
        </Row>
        <LeaveOverviewListDiv>
          <LeaveOverviewList />
        </LeaveOverviewListDiv>
        <CustomDivider m="16px 0px" />
      </OverviewLeave>

      <LeaveOverviewDiv>
        <Row align="middle">
          <B
            type="b-default-semibold"
            pr={leaveData.length > 4 ? '38px' : '50px'}>
            Leave Balance
          </B>
          <Col style={{ maxWidth: '55%' }}>
            <LeaveBalanceComponent
              leaveBalanceUser={leaveData}
              leaveStatus={leaveStatus}
            />
          </Col>

          <FooterComp>
            <PrimaryButton
              size="small"
              htmlType="submit"
              top="50%"
              onClick={() => {
                setVisible(true);
              }}>
              Apply Leave
            </PrimaryButton>
            <Tooltip title="View Log Trail" placement="bottomRight">
              <StyledImage
                className="ant-dropdown-link"
                onClick={() => navigate('/leave/log-trail')}
                src={LogTrailSVG}
              />
            </Tooltip>
          </FooterComp>
        </Row>
        {visible && (
          <ApplyLeaveModal userId={userId} onClose={() => setVisible(false)} />
        )}
      </LeaveOverviewDiv>
      <CustomDivider m="-15px 0px 10px" />
    </>
  );
};
export default LeaveOverview;
