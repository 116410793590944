import styled from '@emotion/styled';
import { Col, Collapse, Form, Row, Spin } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router';
import {
  useGetLoan,
  useUpdateLoan,
  useSettleLoan,
  useAddNote,
  useEditNote,
  useRemoveNote,
  useAddDocument,
  useRemoveDocument,
} from '../../../../api/loanHooks';
import { useGetPeople } from '../../../../api/peopleHooks';
import {
  Div,
  CustomDivider,
  B,
  FooterComponent,
  SecondaryButton,
} from '../../../../components';
import { getAllPeople } from '../../../../store/actions';

import theme from '../../../../theme';
import {
  LoanTypeEnum,
  LoanUpdateI,
  UpdateUserLoanI,
} from '../../../../types/loan.types';
import { numberThousandSeparator } from '../../../../util/utils';
import LoanDetails from '../../../loan/add-loan/LoanDetails';
import SelectEmployee from '../../../loan/add-loan/SelectEmployee';
import NotesAndDocuments from '../../../loan/shared/NotesAndDocuments';
import InstallmentTable from '../../../loan/view-loan/InstallmentTable';
import LoanOverview from '../../../loan/view-loan/LoanOverview';
import { getLatestJob } from '../../../people/PeopleUtil';
import { RootState, store } from '../../../../store';
import dayjs from 'dayjs';

const { Panel } = Collapse;

const CollapseSection = styled(Collapse)`
  border: none;
  background-color: ${theme.white};
  .ant-collapse-header {
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: ${theme.black};
    background: ${theme.gray50} !important;
    border-radius: 4px !important;
    border: 0.5px solid ${theme.gray300};
    padding: 16px 32px !important;
  }
  .ant-collapse-content {
    border-top: none;
    .ant-collapse-content-box {
      padding: 0px !important;
    }
    border: 1px solid ${theme.gray300};
    border-radius: 0px !important;
    margin-top: 4px;
  }
  .ant-collapse-item {
    padding: 0px !important;
    border-radius: 4px !important;
    border-bottom: none;
  }
  .ant-collapse-item-active {
    padding: 0px !important;
  }
  .ant-collapse-header .ant-collapse-arrow {
    top: 17px !important;
    left: 34px !important;
    font-weight: 900 !important;
  }
  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    display: inline-block !important;
    margin-right: 12px !important;
    font-size: 12px !important;
  }
  .ant-collapse-arrow svg {
    margin-top: 6px;
  }
  .ant-collapse-item-active > .ant-collapse-header {
    border-radius: 4px 4px 0px 0px !important;
  }
`;

const LoanForm = styled(Form)`
  .ant-input[disabled],
  .ant-picker-input > input[disabled],
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: ${theme.black};
  }
`;

type ParamsI = {
  id: string;
};

export type AppDispatch = typeof store.dispatch;
const ViewUserLoanPage: FC = () => {
  const navigate = useNavigate();
  const params = useParams<ParamsI>();
  const [form] = Form.useForm();
  const [guarantorList, setGuarantorList] = useState<
    {
      label: string;
      value: number;
    }[]
  >();
  const [isReleased, setIsReleased] = useState(false);
  const allPeoples = useSelector((state: RootState) => state.people.allPeoples);

  const {
    isLoading: loadingGetLoan,
    data: dataGetLoan,
    refetch: getLoan,
  } = useGetLoan(Number(params.id));

  const { isSuccess: successUpdateLoan, mutate: updateLoan } = useUpdateLoan();

  const installments = dataGetLoan?.installment.filter((i) => i.amount > 0);

  const { data: peopleData, mutate: fetchPeople } = useGetPeople();
  const { isSuccess: successSettleLoan, mutate: settleLoan } = useSettleLoan();

  const { isSuccess: successAddNote, mutate: addNote } = useAddNote();
  const { isSuccess: successEditNote, mutate: editNote } = useEditNote();
  const { isSuccess: successRemoveNote, mutate: removeNote } = useRemoveNote();
  const { isSuccess: successAddDocument, mutate: addDocument } =
    useAddDocument();
  const { isSuccess: successRemoveDocument, mutate: removeDocument } =
    useRemoveDocument();

  useEffect(() => {
    if (peopleData) {
      form.setFieldsValue({
        netSalary: numberThousandSeparator(peopleData?.calculation.netSalary),
      });
    }
  }, [peopleData]);

  useEffect(() => {
    if (params.id) {
      getLoan();
    }
    store.dispatch(getAllPeople());
  }, []);

  useEffect(() => {
    if (dataGetLoan) {
      fetchPeople(String(dataGetLoan.userId));
    }
    if (dataGetLoan) {
      form.setFieldsValue({
        userId: dataGetLoan.user.fullName,
        empNumber: dataGetLoan?.user.employeeNumber,
        designation: getLatestJob(dataGetLoan?.user.userHistory)?.designation,
        loanId: dataGetLoan.loanId,
        loanAmount: String(dataGetLoan.loanAmount),
        releaseDate: dayjs(dataGetLoan.releaseDate),
        loanPeriod: dataGetLoan.loanPeriod,
        loanInterest: String(dataGetLoan.loanInterest),
        maturityDate: dayjs(dataGetLoan.maturityDate),
        installmentAmount: Number(dataGetLoan.installmentAmount).toFixed(2),
        noOfInstallments: dataGetLoan.noOfInstallments,
        guarantorId: dataGetLoan.guarantor && dataGetLoan.guarantor.fullName,
        isInternalGuarantor: dataGetLoan.isInternalGuarantor,
        guarantorName: dataGetLoan.guarantorName,
        guarantorContactNumber: dataGetLoan.guarantorContactNumber,
        guarantorEmail: dataGetLoan.guarantorEmail,
      });
      setIsReleased(dataGetLoan.loanReleasedToEmployee);
    }
    if (dataGetLoan?.userId) {
      fetchPeople(String(dataGetLoan.userId));
    }
  }, [dataGetLoan]);

  useEffect(() => {
    if (allPeoples && allPeoples.length) {
      setGuarantorList(
        allPeoples
          .sort((a, b) => a.fullName?.localeCompare(b.fullName))
          .filter(
            (active) => !active.isArchived && active.id !== dataGetLoan?.userId,
          )
          .map((item) => ({
            label: `${item.fullName} ${
              item.employeeNumber ? `(${item.employeeNumber})` : ''
            }`,
            value: item.id,
          })),
      );
    } else {
      setGuarantorList([]);
    }
  }, [dataGetLoan?.userId, allPeoples]);

  useEffect(() => {
    if (
      successUpdateLoan ||
      successAddNote ||
      successRemoveNote ||
      successEditNote ||
      successAddDocument ||
      successRemoveDocument ||
      successSettleLoan
    ) {
      getLoan();
    }
  }, [
    successUpdateLoan,
    successAddNote,
    successRemoveNote,
    successEditNote,
    successAddDocument,
    successRemoveDocument,
    successSettleLoan,
  ]);

  const onFinish = async (values: LoanUpdateI) => {
    const value: LoanUpdateI = {
      releaseDate: dayjs(values.releaseDate).format('YYYY-MM-DD'),
      loanAmount: Number(values.loanAmount),
      loanPeriod: Number(values.loanPeriod),
      loanInterest: values.loanInterest ? Number(values.loanInterest) : 0,
      maturityDate: dayjs(values.maturityDate).format('YYYY-MM-DD'),
      installmentAmount: Number(values.installmentAmount),
      noOfInstallments: values.noOfInstallments,
      loanReleasedToEmployee: isReleased,
      ...(values.guarantorName && { guarantorName: values.guarantorName }),
      ...(values.guarantorEmail && { guarantorEmail: values.guarantorEmail }),
      ...(values.guarantorContactNumber && {
        guarantorContactNumber: values.guarantorContactNumber,
      }),
      ...(values.guarantorId && { guarantorId: values.guarantorId }),
      ...(dataGetLoan.loanType === LoanTypeEnum.DEFAULT && {
        isInternalGuarantor: values.isInternalGuarantor,
      }),
    };

    if (params.id) {
      const dataSet: UpdateUserLoanI = {
        id: Number(params.id),
        data: value,
      };
      await updateLoan(dataSet);
    }
  };
  const refreshLoanData = () => {
    getLoan();
  };

  return (
    <div>
      <Spin spinning={loadingGetLoan}>
        <Div p="32px 40px 0px 48px">
          <LoanOverview
            isUserView={true}
            loandata={dataGetLoan}
            onSubmit={(values) => {
              if (params.id) {
                const v = {
                  id: Number(params.id),
                  data: values,
                };
                settleLoan(v);
              }
            }}
          />
          <CustomDivider
            mt={24}
            mb={0}
            borderTop={`1px solid ${theme.gray300}`}
          />
        </Div>

        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={19}>
            <LoanForm form={form} onFinish={(v) => onFinish(v as LoanUpdateI)}>
              <Div p="32px 40px 32px 48px">
                <CollapseSection>
                  <Panel
                    header={
                      <B type="b-large" pl="32px">
                        Employee Details
                      </B>
                    }
                    key="1">
                    <Div p="24px 32px">
                      <SelectEmployee disableFields />
                    </Div>
                  </Panel>
                </CollapseSection>
              </Div>
              <CustomDivider m={0} borderTop={`0.5px solid ${theme.gray300}`} />
              <Div p="32px 40px 32px 48px">
                <B type="b-large-semibold" pb="32Px">
                  Employee Loan Details
                </B>
                <LoanDetails
                  isDraftLoan={false}
                  disableFields={true}
                  GuarantorList={guarantorList ? guarantorList : []}
                  internal={dataGetLoan?.isInternalGuarantor}
                />
                <Div display="flex" pb="12px" pt="8px">
                  <B type="b-large-semibold" pr="6px">
                    Installment
                  </B>
                </Div>
                <InstallmentTable
                  data={installments}
                  isLoadingdata={loadingGetLoan}
                />
              </Div>
              <CustomDivider m={0} borderTop={`0.5px solid ${theme.gray300}`} />
              <Div p="32px 40px 62px  48px">
                <NotesAndDocuments
                  isUserView={true}
                  isViewLoan={true}
                  userId={dataGetLoan && dataGetLoan.userId}
                  onStatusChange={refreshLoanData}
                  note={dataGetLoan && dataGetLoan.notes}
                  documents={dataGetLoan && dataGetLoan.document}
                  onAddNewNote={(values) => {
                    const v = {
                      loanId: dataGetLoan?.id,
                      ...values,
                    };
                    addNote(v);
                  }}
                  onRemoveNote={(id) => {
                    const selectedNote =
                      dataGetLoan &&
                      dataGetLoan.notes.find((note) => note.id === id);
                    if (selectedNote) {
                      removeNote(Number(selectedNote.id));
                    }
                  }}
                  onEditNote={(id, value) => {
                    const selectedNote =
                      dataGetLoan &&
                      dataGetLoan.notes.find((note) => note.id === id);
                    if (selectedNote) {
                      editNote({
                        id: Number(selectedNote.id),
                        note: value.note,
                        reminderDate: value.reminderDate,
                        loanId: dataGetLoan?.loanId,
                      });
                    }
                  }}
                  onSubmitDocument={(values) => {
                    const v = {
                      loanId: dataGetLoan?.id,
                      ...values,
                    };
                    addDocument(v);
                  }}
                  onRemoveDocument={(id) => {
                    const selectedDocument =
                      dataGetLoan &&
                      dataGetLoan.document.find(
                        (document) => document.id === id,
                      );
                    if (selectedDocument) {
                      removeDocument(Number(selectedDocument.id));
                    }
                  }}
                />
              </Div>
              <FooterComponent
                leftChildren={
                  <SecondaryButton
                    marginLeft={23}
                    onClick={() => navigate('/account-details/loan-details')}>
                    Back
                  </SecondaryButton>
                }
              />
            </LoanForm>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default ViewUserLoanPage;
