import { useEffect, useState } from 'react';

import { clone, cloneDeep, sum } from 'lodash';
import moment, { Moment } from 'moment';

import { LEAVE_LIST } from '../../../configs/employeeConfigs';
import {
  OrganizationBodyI,
  OrganizationHolidayI,
} from '../../../types/organization.types';
import {
  HOURS_PER_DAY,
  LeaveBalance,
  LeaveNameEnum,
  LeaveNameI,
  LeaveNameOption,
  LeaveTypesEnum,
  LeaveViewI,
  OrganizationLeaveI,
  Permission,
  SHORT_LEAVE_HOURS_PER_DAY,
  TIME_FORMAT,
  UserLeaveI,
} from '../../../types';
import { isAllowed, isManager } from '../../../util/permissionUtil';
import { isSameDay } from '../../../util/utils';
import {
  getDurationInHoursAsFloat,
  getDurationInHoursMinutes,
  getHoursDaysFromHours,
} from './dateAndTime.util';
import { getCurrentAuthPayload } from '../../../util/storageUtil';
import { disableSickleavesOrg } from '../../../configs/appConfig';
import { LeaveStatus } from '../../home/homeUtil';
import { camelCaseToNormalText } from '../../../util/camelCaseToNormal.util';
import getNormalText from '../../../util/getNormalText';

export const onTimeChange = (
  value: Moment,
  leaveDates: LeaveViewI[],
  item: LeaveViewI,
  i: number,
  type: 'from' | 'to',
) => {
  if (type == 'from') leaveDates[i].fromTime = value.format(TIME_FORMAT);
  if (type == 'to') leaveDates[i].toTime = value.format(TIME_FORMAT);
  leaveDates[i].description = getDurationInHoursMinutes(
    item?.fromTime,
    item?.toTime,
  );
  leaveDates[i].hours = getDurationInHoursAsFloat(item?.fromTime, item?.toTime);
  return cloneDeep(leaveDates);
};

export const onHoursChange = (
  value: string,
  leaveDates: LeaveViewI[],
  i: number,
) => {
  leaveDates[i].hours = parseFloat(value);
  return cloneDeep(leaveDates);
};

export const calculateLeavesBalance = (
  annualLeaves: number,
  casualLeaves: number,
  sickLeaves: number,
): number => {
  return annualLeaves + casualLeaves + sickLeaves;
};

export const DEFAULT_LEAVE_LIST = {
  annualLeaves: 0,
  sickLeaves: 0,
  casualLeaves: 0,
  shortLeaves: 0,
  noPay: 0,
};
const ORGANIZATION_DURATION = {
  startTime: '08.00AM',
  endTime: '05.00PM',
};
export const useGetLeaveStatus = (leaveSummery: LeaveBalance[]) => {
  const [leaveStatus, setLeaveStatus] =
    useState<LeaveNameI>(DEFAULT_LEAVE_LIST);

  useEffect(() => {
    const leaveStatusCopy = { ...DEFAULT_LEAVE_LIST };

    if (!leaveSummery || leaveSummery.length === 0) {
      setLeaveStatus(leaveStatusCopy);
      return;
    }

    if (leaveSummery && leaveSummery.length) {
      leaveSummery.forEach((item) => {
        if (item.leaveName == LeaveNameEnum.ANNUAL_LEAVE) {
          leaveStatusCopy.annualLeaves = item.balance;
        } else if (item.leaveName == LeaveNameEnum.SICK_LEAVE) {
          leaveStatusCopy.sickLeaves = item.balance;
        } else if (item.leaveName == LeaveNameEnum.CASUAL_LEAVE) {
          leaveStatusCopy.casualLeaves = item.balance;
        } else if (item.leaveName == LeaveNameEnum.NO_PAY) {
          leaveStatusCopy.noPay = item.balance;
        } else if (item.leaveName == LeaveNameEnum.SHORT_LEAVE) {
          leaveStatusCopy.shortLeaves = item.balance;
        } else {
          leaveStatusCopy[item.leaveName] = item.balance;
        }
      });
      setLeaveStatus(leaveStatusCopy);
    }
  }, [leaveSummery]);

  return leaveStatus;
};

export const getLeaveBalance = (
  type: string | null,
  leaveStatus: LeaveNameI,
) => {
  switch (type) {
    case LeaveNameEnum.ANNUAL_LEAVE:
      return `Annual Leave Balance : ${
        getHoursDaysFromHours(leaveStatus.annualLeaves).text
      }`;
    case LeaveNameEnum.CASUAL_LEAVE:
      return `Casual Leave Balance : ${
        getHoursDaysFromHours(leaveStatus.casualLeaves).text
      }`;
    case LeaveNameEnum.SICK_LEAVE:
      return `Sick Leave Balance : ${
        getHoursDaysFromHours(leaveStatus.sickLeaves).text
      }`;
    case LeaveNameEnum.NO_PAY:
      return `No Pay Balance : ${
        getHoursDaysFromHours(leaveStatus.noPay).text
      }`;
    case LeaveNameEnum.SHORT_LEAVE:
      return `Short Leave Balance : ${leaveStatus.shortLeaves}`;
    default:
      return null;
  }
};

export const getLeaveTypes = (
  leaveSummery: LeaveBalance[] | null,
  otherLeaveLength: number,
  noPayAllowed?: boolean,
  organizationData?: OrganizationBodyI,
) => {
  // to add labels and values of the custom leave types as an object
  const customLeaveTypeList =
    leaveSummery?.filter(
      (items) =>
        items.leaveType === LeaveTypesEnum.CUSTOM && items.totalLeave > 0,
    ) || [];

  const customLeaveTypes = organizationData?.customLeaveTypes;

  const CUSTOM_LEAVES_LIST = customLeaveTypeList
    ?.filter((balance) =>
      customLeaveTypes?.some(
        (type) => type.customLeaveName === balance.leaveName && type.isEnable,
      ),
    )
    .map((customLeaves) => {
      return { label: customLeaves.leaveName, value: customLeaves.leaveName };
    });

  const isShortLeaveExist = leaveSummery?.some(
    (summery) => summery.leaveName === LeaveNameEnum.SHORT_LEAVE,
  );
  const shortLeavesPerMonth = organizationData?.shortLeaves?.limitPerMonth;

  const MERGED_LIST = [
    ...LEAVE_LIST.filter(
      (item) => item.value !== LeaveNameEnum.SHORT_LEAVE || (isShortLeaveExist && shortLeavesPerMonth > 0),
    ),
    ...CUSTOM_LEAVES_LIST,
  ];

  return MERGED_LIST.filter((item) => {
    if (item.value === 'NO_PAY') {
      return (
        isAllowed(Permission.CREATE_EDIT_LEAVES_USER) ||
        (isManager() && noPayAllowed)
      );
    } else if (
      getCurrentAuthPayload()?.organizationId === disableSickleavesOrg &&
      item.value === LeaveNameEnum.SICK_LEAVE
    ) {
      return false;
    } else {
      return true;
    }
  }).map((leaveType) => {
    let newLeaveType: {
      label: string;
      value: string;
      disabled?: boolean;
    } = leaveType;
    const summery =
      leaveSummery &&
      leaveSummery.find(
        (summery: LeaveBalance) => leaveType.value === summery.leaveName,
      );
    if (summery) {
      newLeaveType = { ...leaveType, disabled: summery.balance <= 0 };
    }
    if (leaveType.value === LeaveTypesEnum.OTHER) {
      newLeaveType = { ...leaveType, disabled: otherLeaveLength <= 0 };
    }
    return newLeaveType;
  });
};

export const getOtherLeaveTypes = (
  leaveNameOptions: LeaveNameOption[] | undefined,
  leaveSummery: LeaveBalance[] | null,
) => {
  return leaveNameOptions
    ? leaveNameOptions.map((leaveName) => {
        const summery = leaveSummery?.find(
          (summery) => leaveName.value === summery.leaveName,
        );
        if (summery) {
          return { ...leaveName, disabled: summery.balance <= 0 };
        }
        return leaveName;
      })
    : [];
};

export const onTypeChange = ( 
  dateRanges: string[],
  leaveDates: LeaveViewI[],
  userLeaveData: OrganizationLeaveI[] | null,
  organizationData: {
    organizationHoliday: OrganizationHolidayI[];
    startTime?: string;
    endTime?: string;
  } | null,
  userId?: string,
  isShortLeave?: boolean,
) => {

  const newDateRange = dateRanges.map((aDay) => {
    let isHoliday = false;
    let description = '8H';
    let alreadyTaken: string = null;
    let leaveHours = isShortLeave ? SHORT_LEAVE_HOURS_PER_DAY : HOURS_PER_DAY;

    (organizationData?.organizationHoliday || []).forEach((holiday) => {
      if (isSameDay(moment(holiday.date), moment(aDay)) && holiday.isHoliday) {
        isHoliday = true;
        leaveHours = HOURS_PER_DAY;
        description = holiday.description || '';
      }
    });

    const userLeaves = userLeaveData?.filter(
      (leaves) =>
        leaves.userId === userId && leaves.status !== LeaveStatus.REJECTED,
    );

    userLeaves?.forEach((leaves) => {
      if (isSameDay(moment(leaves.date), moment(aDay))) {
        isHoliday = true;
        leaveHours = leaves.hours;
        alreadyTaken =
          'You have already submitted a leave request for this date.';
      }
    });

    const dayOfWeek = moment(aDay).isoWeekday();
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 7;
    if (isWeekend) {
      isHoliday = true;
      description = 'Weekend';
      leaveHours = HOURS_PER_DAY;
    }

    return {
      isHoliday,
      date: aDay,
      description: description,
      alreadyTaken: alreadyTaken,
      hours: leaveHours,
      fromTime: moment(
        organizationData?.startTime || ORGANIZATION_DURATION.startTime,
        ['h:mmA'],
      ).format(TIME_FORMAT),
      toTime: moment(
        organizationData?.endTime || ORGANIZATION_DURATION.endTime,
        ['h:mmA'],
      ).format(TIME_FORMAT),
    };
  });

  return newDateRange;
};

export const onDateRangeChanged = (
  dateRanges: string[],
  leaveDates: LeaveViewI[],
  userLeaveData: OrganizationLeaveI[] | null,
  organizationData: {
    organizationHoliday: OrganizationHolidayI[];
    startTime?: string;
    endTime?: string;
  } | null,
  userId?: string,
  isShortLeave?: boolean,
) => {
  const curentLeaveDates = clone(leaveDates);

  const newDateRange = dateRanges.map((aDay) => {
    let isHoliday = false;
    let description = '8H';
    let alreadyTaken: string = null;
    let leaveHours = isShortLeave ? SHORT_LEAVE_HOURS_PER_DAY : HOURS_PER_DAY;

    if (curentLeaveDates?.length ) {
      const isExist = curentLeaveDates?.find(
        (leaveDate) => leaveDate.date === aDay,
      );
      if (isExist) {
        return isExist;
      }
    }

    (organizationData?.organizationHoliday || []).forEach((holiday) => {
      if (isSameDay(moment(holiday.date), moment(aDay)) && holiday.isHoliday) {
        isHoliday = true;
        leaveHours = HOURS_PER_DAY;
        description = holiday.description || '';
      }
    });

    const userLeaves = userLeaveData?.filter(
      (leaves) =>
        leaves.userId === userId && leaves.status !== LeaveStatus.REJECTED,
    );

    userLeaves?.forEach((leaves) => {
      if (isSameDay(moment(leaves.date), moment(aDay))) {
        isHoliday = true;
        leaveHours = leaves.hours;
        alreadyTaken =
          'You have already submitted a leave request for this date.';
      }
    });

    const dayOfWeek = moment(aDay).isoWeekday();
    const isWeekend = dayOfWeek === 6 || dayOfWeek === 7;
    if (isWeekend) {
      isHoliday = true;
      description = 'Weekend';
      leaveHours = HOURS_PER_DAY;
    }

    return {
      isHoliday,
      date: aDay,
      description: description,
      alreadyTaken: alreadyTaken,
      hours: leaveHours,
      fromTime: moment(
        organizationData?.startTime || ORGANIZATION_DURATION.startTime,
        ['h:mmA'],
      ).format(TIME_FORMAT),
      toTime: moment(
        organizationData?.endTime || ORGANIZATION_DURATION.endTime,
        ['h:mmA'],
      ).format(TIME_FORMAT),
    };
  });

  return newDateRange;
};

export function onLeaveDayClose(
  value: LeaveViewI,
  leaveDates: LeaveViewI[],
  setStartValue: (value: React.SetStateAction<string | undefined>) => void,
  setEndValue: (value: React.SetStateAction<string | undefined>) => void,
  setLeaveDates: (value: React.SetStateAction<LeaveViewI[]>) => void,
) {
  if (leaveDates.length > 1) {
    //change form from date if the first date is removed
    if (leaveDates[0].date === value.date) {
      setStartValue(leaveDates[1].date);
    }
    //change form To date if the last date is removed
    if (leaveDates[leaveDates.length - 1].date === value.date) {
      setEndValue(leaveDates[leaveDates.length - 2].date);
    }
    setLeaveDates(
      cloneDeep(leaveDates.filter((item) => item.date !== value.date)),
    );
  }
}
