import { FC } from 'react';
import { HOURS_PER_DAY } from '../../../types';
import moment from 'moment';
import { getTimeUnitNames } from '../utils/dateAndTime.util';
import { Col, Row } from 'antd';
import styled from '@emotion/styled';

interface Props {
  hours: number;
  isShort?: boolean;
  isShortLeave?:boolean;
}

const StyledRow = styled(Row)`
  display: flex;
  flex-direction: row;
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: row;
`;

const HoursDaysFromHoursComponent: FC<Props> = ({ hours, isShort = false, isShortLeave= false }) => {
  let text = '';
  if (hours < 0) {
    text += '-';
    hours = Math.abs(hours);
  }

  const h = hours % HOURS_PER_DAY;
  const hour = moment.duration(h, 'hours');
  const days = Math.floor(hours / HOURS_PER_DAY);
  const timeUnitNames = getTimeUnitNames(isShort,{days: days, hour: hour.hours(), minute:hour.minutes()} );
  let day = ' ';
  let hr = ' ';
  let mins = ' ';

  if (days > 0) {
    day = `${days.toString().padStart(2, '0')}${timeUnitNames.days}`;
  }
  if (hour.hours() > 0) {
    hr = `${hour.hours().toString().padStart(2, '0')}${timeUnitNames.hour}`;
  }
  if (hour.minutes() > 0) {
    mins = `${hour.minutes().toString().padStart(2, '0')}${
      timeUnitNames.minute
    }`;
  }
  return (
    <>
      <StyledRow>
        <StyledCol xs={24} sm={24} md={8} lg={8} xl={8}>
          {!isShortLeave ? day : ''}
        </StyledCol>
        <StyledCol xs={24} sm={24} md={8} lg={8} xl={8}>
        {!isShortLeave ? hr : `${hours | 0} Hours` }
        </StyledCol>
        <StyledCol xs={24} sm={24} md={8} lg={8} xl={8}>
          {!isShortLeave ? mins : ''}
        </StyledCol>
      </StyledRow>
    </>
  );
};

export default HoursDaysFromHoursComponent;
