import React, { FC, useEffect } from 'react';
import { Col, Form, FormInstance, Row } from 'antd';
import styled from '@emotion/styled';
import theme from '../../../theme';
import { space, SpaceProps, layout, LayoutProps } from 'styled-system';
import { InputComp } from '../../../components';

import { Validation } from '../../../common/validation';
import { ShortLeaveI } from '../../../types/organization.types';

const InputTag = styled.div`
  background: ${theme.gray100};
  padding: 5px 7px;
  border-left: 1px solid ${theme.gray400};
  box-sizing: border-box;
  border-radius: 0px 4px 4px 0px;
  margin-right: -12px;
`;

const FormItem = styled(Form.Item)<SpaceProps & LayoutProps>`
  margin-bottom: 0px;
  ${space}
  ${layout}
`;
interface Props {
  shortLeavesData: ShortLeaveI;
  form: FormInstance<any>;
}

const ShortLeaveBalance: FC<Props> = ({ shortLeavesData, form }) => {
  useEffect(() => {
    form.setFieldsValue({
      limitPerDay: shortLeavesData?.limitPerDay,
      limitPerMonth: shortLeavesData?.limitPerMonth,
    });
  }, [shortLeavesData]);
  return (
    <Row gutter={[20, 0]}>
      <>
        <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
          <FormItem
            name="limitPerDay"
            initialValue={shortLeavesData?.limitPerDay}
            label="Per Day"
            rules={[
              {
                pattern: Validation.NoHoursDaysInADay,
                message: 'Should be between 0 and 24',
              },
            ]}>
            <InputComp
              size="small"
              suffix={<InputTag>hours</InputTag>}
              placeholder={`Daily Balance`}
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
          <FormItem
            name="limitPerMonth"
            initialValue={shortLeavesData?.limitPerMonth}
            label="Per Month"
            rules={[
              {
                pattern: Validation.NoOfMaxDaysPerMonthPattern,
                message: 'Should be between 0 and 31',
              },
            ]}>
            <InputComp
              size="small"
              suffix={<InputTag>days</InputTag>}
              placeholder={`Monthly Balance`}
            />
          </FormItem>
        </Col>
      </>
    </Row>
  );
};

export default ShortLeaveBalance;
