import styled from '@emotion/styled';
import { Col, Form, message, Row, Spin } from 'antd';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useCreateLoan, useGetLoanId } from '../../../api/loanHooks';
import { useGetPeople } from '../../../api/peopleHooks';
import {
  AlertMessage,
  B,
  CustomDivider,
  Div,
  FooterComponent,
  PrimaryButton,
  RouteLeavingGuard,
  SecondaryButton,
} from '../../../components';
import { getAllPeople } from '../../../store/modules/people';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import {
  AddLoanNoteI,
  LoanCreateI,
  LoanDocumentI,
  LoanTypeEnum,
} from '../../../types/loan.types';
import { PeopleI } from '../../../types/people.types';
import { getApiError } from '../../../util/getApiError';
import { getPathWithOrgId } from '../../../util/getPathWithOrgId';
import { numberThousandSeparator } from '../../../util/utils';
import { getLatestJob } from '../../people/PeopleUtil';
import NotesAndDocuments from '../shared/NotesAndDocuments';
import LoanDetails from './LoanDetails';
import SelectEmployee from './SelectEmployee';
import {
  installmentAmountCal,
  loanInterestAmountCal,
} from '../../../util/loanCalculations';
import dayjs from 'dayjs';

const LoanForm = styled(Form)`
  .ant-input[disabled],
  .ant-picker-input > input[disabled],
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: ${theme.black};
  }
`;

const AddLoanPage: FC = () => {
  const navigate = useNavigate();
  const dispatch: DispatchType = useDispatch();
  const [form] = Form.useForm();
  const [selectUser, setSelectUser] = useState<number>();
  const [releaseDate, setReleaseDate] = useState<string>();
  const [loanPeriod, setLoanPeriod] = useState<number>();
  const [loanAmount, setLoanAmount] = useState<number>();
  const [loanInterest, setLoanInterest] = useState<number>();
  const [note, setNote] = useState<AddLoanNoteI[]>([]);
  const [document, setDocument] = useState<LoanDocumentI[]>([]);
  const [loanType, setLoanType] = useState<string>();
  const [guarantorList, setGuarantorList] = useState<
    {
      label: string;
      value: number;
    }[]
  >();
  const [isFieldDataChanged, setIsFieldDataChanged] = useState(false);
  const allPeoples = useSelector((state: RootState) => state.people.allPeoples);

  const { data: peopleData, mutate: fetchPeople } = useGetPeople();
  const {
    isLoading: loadingCreateLoan,
    error: errorCreateLoan,
    data: dataCreateLoan,
    mutate: createLoan,
  } = useCreateLoan();
  const { isLoading: loadingGetLoanId, data: dataGetLoanId } = useGetLoanId();

  let loanInterestAmount;
  let installmentAmount;
  if (loanAmount && loanPeriod) {
    loanInterestAmount = loanInterestAmountCal(loanAmount, loanInterest);

    installmentAmount = installmentAmountCal(
      loanAmount,
      loanPeriod,
      loanInterestAmount,
    );
  }

  let isNotValidLoan = false;
  const monthlySalary = peopleData?.calculation?.netSalary;

  if (loanAmount && loanType) {
    isNotValidLoan =
      loanType === LoanTypeEnum.DEFAULT
        ? monthlySalary < installmentAmount
        : monthlySalary < loanAmount;
  }

  useEffect(() => {
    if (peopleData) {
      form.setFieldsValue({
        netSalary: numberThousandSeparator(peopleData?.calculation.netSalary),
      });
    }
  }, [peopleData]);

  useEffect(() => {
    dispatch(getAllPeople());
  }, []);

  useEffect(() => {
    if (selectUser) {
      fetchPeople(String(selectUser));
    }
  }, [selectUser]);

  useEffect(() => {
    if (dataGetLoanId) {
      form.setFieldsValue({
        loanId: dataGetLoanId,
        isInternalGuarantor: true,
      });
    }
  }, [dataGetLoanId]);

  // success handling
  useEffect(() => {
    if (dataCreateLoan && !errorCreateLoan) {
      message.success('Created Successfully!');
      // navigate to loan home page
      navigate(getPathWithOrgId('/loans'));
    }
  }, [dataCreateLoan]);

  // error handling
  useEffect(() => {
    errorCreateLoan && message.error(getApiError(errorCreateLoan));
  }, [errorCreateLoan]);

  const setSelectedUser = (id: number) => {
    const user: PeopleI | undefined = allPeoples.find((i) => i.id === id);
    if (user) {
      form.setFieldsValue({
        user: user?.id,
        empNumber: user.employeeNumber,
        designation: getLatestJob(user.userHistory)?.designation,
      });
    }
  };

  const onChangeLoanAmount = (loanAmount: number) => {
    if (loanPeriod && loanAmount && loanInterest) {
      const loanInterestAmount = loanInterestAmountCal(
        loanAmount,
        loanInterest,
      );
      const installmentAmount = installmentAmountCal(
        loanAmount,
        loanPeriod,
        loanInterestAmount,
      );

      form.setFieldsValue({
        installmentAmount: installmentAmount.toFixed(2),
      });
    }
    if (!loanInterest && loanAmount && loanPeriod) {
      const installmentAmount = loanAmount / loanPeriod;
      form.setFieldsValue({
        installmentAmount: installmentAmount.toFixed(2),
      });
    }
  };

  const onChangeLoanInterest = (loanInterest: number) => {
    if (loanInterest && loanPeriod && loanAmount) {
      const loanInterestAmount = loanInterestAmountCal(
        loanAmount,
        loanInterest,
      );
      const installmentAmount = installmentAmountCal(
        loanAmount,
        loanPeriod,
        loanInterestAmount,
      );
      form.setFieldsValue({
        installmentAmount: installmentAmount.toFixed(2),
      });
    }
    if (!loanInterest && loanAmount && loanPeriod) {
      const installmentAmount = loanAmount / loanPeriod;
      form.setFieldsValue({
        installmentAmount: installmentAmount.toFixed(2),
      });
    }
  };

  const onChangeLoanPeriod = (loanPeriod: number) => {
    if (loanPeriod) {
      form.setFieldsValue({
        noOfInstallments: loanPeriod,
      });
    }
    if (loanPeriod && releaseDate) {
      const maturityDate = moment(releaseDate)
        .add(loanPeriod, 'months')
        .calendar();
      form.setFieldsValue({
        maturityDate: dayjs(maturityDate),
      });
    }
    if (loanInterest && loanPeriod && loanAmount) {
      const loanInterestAmount = loanInterestAmountCal(
        loanAmount,
        loanInterest,
      );
      const installmentAmount = installmentAmountCal(
        loanAmount,
        loanPeriod,
        loanInterestAmount,
      );
      form.setFieldsValue({
        installmentAmount: installmentAmount.toFixed(2),
      });
    }
    if (!loanInterest && loanAmount && loanPeriod) {
      const installmentAmount = loanAmount / loanPeriod;
      form.setFieldsValue({
        installmentAmount: installmentAmount.toFixed(2),
      });
    }
  };

  const onChangeReleaseDate = (releaseDate: string) => {
    if (releaseDate && loanPeriod) {
      const maturityDate = moment(releaseDate)
        .add(loanPeriod, 'months')
        .calendar();
      form.setFieldsValue({
        maturityDate: dayjs(maturityDate),
      });
    }
  };

  const shouldBlockNavigation = () => {
    return isFieldDataChanged;
  };

  const onValuesChange = () => {
    setIsFieldDataChanged(true);
  };

  const onFinish = (values: LoanCreateI) => {
    setIsFieldDataChanged(false);
    const formData = new FormData();

    formData.append('releaseDate', values.releaseDate.format('YYYY-MM-DD'));
    formData.append('loanAmount', values.loanAmount);
    formData.append('loanId', values.loanId);
    formData.append('loanType', values.loanType);
    formData.append('isInternalGuarantor', String(values.isInternalGuarantor));
    values.loanPeriod && formData.append('loanPeriod', values.loanPeriod);
    values.loanInterest
      ? formData.append('loanInterest', values.loanInterest)
      : formData.append('loanInterest', '0');
    values.maturityDate &&
      formData.append('maturityDate', values.maturityDate.format('YYYY-MM-DD'));
    values.installmentAmount &&
      formData.append('installmentAmount', values.installmentAmount);
    values.noOfInstallments &&
      formData.append('noOfInstallments', values.noOfInstallments);
    values.guarantorId && formData.append('guarantorId', values.guarantorId);
    values.guarantorName &&
      formData.append('guarantorName', String(values.guarantorName));
    values.guarantorEmail &&
      formData.append('guarantorEmail', values.guarantorEmail);
    values.guarantorContactNumber &&
      formData.append('guarantorContactNumber', values.guarantorContactNumber);

    const notes = note?.map((item) => ({
      note: item.note,
      ...(item.reminderDate && { reminderDate: item.reminderDate }),
    }));
    notes && formData.append('notes', JSON.stringify(notes));

    document.map((item) => {
      if (item) {
        formData.append('documentFiles', item.document, `${item.name}.pdf`);
      }
    });

    values.userId && formData.append('userId', values.userId);
    createLoan(formData);
  };

  useEffect(() => {
    if (allPeoples && allPeoples.length) {
      setGuarantorList(
        allPeoples
          .sort((a, b) => a.fullName?.localeCompare(b.fullName))
          .filter((active) => !active.isArchived && active.id !== selectUser)
          .map((item) => ({
            label: `${item.fullName} ${
              item.employeeNumber ? `(${item.employeeNumber})` : ''
            }`,
            value: item.id,
          })),
      );
    } else {
      setGuarantorList([]);
    }
  }, [selectUser]);

  return (
    <Spin spinning={loadingGetLoanId}>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={19}>
          <Div p={32}>
            <LoanForm
              form={form}
              onFinish={(v) => onFinish(v as LoanCreateI)}
              onValuesChange={onValuesChange}>
              <RouteLeavingGuard
                when={isFieldDataChanged}
                navigate={(path) => navigate(path)}
                shouldBlockNavigation={shouldBlockNavigation}
              />
              <Div
                border={`0.5px solid ${theme.gray300}`}
                p={32}
                borderRadius="4px">
                <B type="b-large-semibold" pb="32px">
                  Select Employee Name
                </B>
                <SelectEmployee
                  allPeoples={allPeoples}
                  onChange={(e) => {
                    setSelectedUser(Number(e));
                    setSelectUser(Number(e));
                  }}
                />
                <CustomDivider
                  mt="8px"
                  mb="32px"
                  borderTop={`1px solid ${theme.gray300}`}
                />
                <B type="b-large-semibold" pb="32px">
                  Enter Employee Loan Details
                </B>
                <LoanDetails
                  onChangeLoanAmount={(e) => {
                    onChangeLoanAmount(Number(e));
                    setLoanAmount(Number(e));
                  }}
                  onChangeLoanPeriod={(e) => {
                    onChangeLoanPeriod(Number(e));
                    setLoanPeriod(Number(e));
                  }}
                  onChangeReleaseDate={(e) => {
                    onChangeReleaseDate(String(e));
                    setReleaseDate(String(e));
                  }}
                  onChangeLoanInterest={(e) => {
                    onChangeLoanInterest(Number(e.target.value));
                    setLoanInterest(Number(e.target.value));
                  }}
                  GuarantorList={guarantorList ? guarantorList : []}
                  userNetSalary={peopleData?.calculation.netSalary}
                  internal={true}
                  onChangeLoanType={(type) => setLoanType(String(type))}
                />
                {isNotValidLoan && (
                  <AlertMessage
                    type="error"
                    title="Loan instalment size is larger than the last drawn net salary, please adjust accordingly "></AlertMessage>
                )}
              </Div>
              <Div mt={32} mb="50px">
                <NotesAndDocuments
                  note={note}
                  documents={document}
                  onAddNewNote={(values) => {
                    const noteList = note;
                    noteList.push(values);
                    setNote(noteList);
                  }}
                  onRemoveNote={(id) => {
                    const noteList = note;
                    const setId = noteList.findIndex((note) => note.id === id);
                    noteList.splice(setId, 1);
                    setNote([...noteList]);
                  }}
                  onEditNote={(id, value) => {
                    const noteList = note;
                    const setId = noteList.find((note) => note.id === id);
                    if (setId) {
                      setId.note = value.note;
                      setId.reminderDate=value.reminderDate;
                    }
                    setNote([...noteList]);
                  }}
                  onSubmitDocument={(values: LoanDocumentI) => {
                    const documentList = document;
                    documentList.push(values);
                    setDocument(documentList);
                  }}
                  onRemoveDocument={(id) => {
                    const documentList = document;
                    const setId = documentList.findIndex(
                      (document) => document.id === id,
                    );
                    documentList.splice(setId, 1);
                    setDocument([...documentList]);
                  }}
                />
              </Div>
              <FooterComponent
                leftChildren={
                  <SecondaryButton
                    onClick={() => navigate(getPathWithOrgId('/loans'))}>
                    Back
                  </SecondaryButton>
                }
                rightChildren={
                  <PrimaryButton
                    ml={16}
                    loading={loadingCreateLoan}
                    disabled={isNotValidLoan}
                    htmlType="submit">
                    Submit
                  </PrimaryButton>
                }
              />
            </LoanForm>
          </Div>
        </Col>
      </Row>
    </Spin>
  );
};

export default AddLoanPage;
