import React, { FC } from 'react';
import { B } from '../../../components';
import { getHoursDaysFromHours } from '../utils';
import styled from '@emotion/styled';
import theme from '../../../theme';
import { Card } from 'antd';

interface Props {
  leaveTitle: string;
  leavesBalance: number;
  isMobile?: boolean;
  isShort?: boolean;
  isShortLeave?:boolean;
}

const gridStyle: React.CSSProperties = {
  textAlign: 'center',
  padding: `10px 20px`,
  marginBlock: '0px',
  // marginInline: '-25px',
};

export const StyledCard = styled.div`
 text-align: left !important;
  @media only screen and (max-width: 768px) {
    align-items: center !important;
    padding: 4px 0px;
  }

`;
const LeavesBalance: FC<Props> = ({
  leaveTitle,
  leavesBalance,
  isMobile = false,
  isShort = false,
  isShortLeave = false,
}) => {
  const hourDayText = isShortLeave ? `${leavesBalance} Hours`:getHoursDaysFromHours(leavesBalance, isShort).text;
  return (
    <>
      {isMobile ? (
        <StyledCard>
          <B type="b-default" color="#757575">
            {leaveTitle}
          </B>

          <B type="b-large-semibold" color="#000000">
            {leavesBalance ? hourDayText : '-'}
          </B>
        </StyledCard>
      ) : (
        <StyledCard
          style={gridStyle}>
          <B type="b-large-semibold"> {leavesBalance ? hourDayText : '-'}</B>
          <B type="b-small" color="#757575">
            {leaveTitle}
          </B>
        </StyledCard>
      )}
    </>
  );
};
export default LeavesBalance;
