import React from 'react';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import theme from '../theme';
import styled from '@emotion/styled';

// This "any" is from the AntTable
interface Props<T extends object = any> extends TableProps<T> {
  withBorders?: boolean;
  rowSize?: number;
  headerFontSize?: number;
  fullWidth?: boolean;
  size?: 'small' | 'middle' | 'large';
}

type props = {
  minHeight: string;
  dataSourceLength?: number;
  headerFontSize?: number;
  rowSize?: number;
  fullWidth: boolean;
  withBorders: boolean;
};
const StyledTable = styled(Table)<props>`
  .ant-table-body {
    min-height: ${(props) => props.minHeight};
    padding-top: ${(props) => (props.dataSourceLength === 0 ? '7%' : '0')};
    overflow: auto !important;
    padding: 50px !important;
  }

  .ant-table {
    margin-block: -8px !important;
    margin-inline: -24px 0px !important;
  }

  .ant-table-empty .ant-table-cell {
    background: transparent !important;
  }

  .yellow-highlight > .ant-table-cell {
    background: ${theme.warn} !important;
  }

  .ant-table-thead > tr > th,
  .ant-table.ant-table-middle .ant-table-thead > tr > th {
    background: ${theme.gray50} !important;
    font-size: ${(props) =>
      props.headerFontSize ? `${props.headerFontSize}px` : '14px'};
    line-height: 20px;
    color: ${theme.gray600};
    height: ${(props) => (props.rowSize ? `${props.rowSize}px` : '40px')};
    padding: 0;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    border-bottom: 1px solid ${theme.gray300} !important;
    padding-left: ${(props) =>
      props.fullWidth ? '150px' : '100px'} !important;
  }

  .ant-table-thead > tr > th {
    border-right: none !important;
  }

  .ant-table-thead > tr > td {
    border-bottom: 1px solid ${theme.gray300} !important;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }

  .ant-table-tbody > tr {
    border-bottom: ${(props) =>
      props.dataSourceLength === 0
        ? ' none'
        : `1px solid ${theme.gray300}`} !important;
  }

  .ant-table-tbody > tr > td {
    padding-left: ${(props) =>
      props.fullWidth ? '150px' : '100px'} !important;
    background: ${theme.white};
    padding-right: 0px !important;
    padding-top: 7.5px;
    padding-bottom: 7.5px;
    font-size: 12px;
  }

  .ant-table-column-sorters {
    padding: 0px !important;
  }

  .ant-table-placeholder {
    .ant-table-cell {
      border-bottom: none;
    }
  }

  .error-row {
    background-color: ${theme.red300};
    height: 100%;
    width: 6px;
    top: 1px;
    left: 0;
    position: absolute;
  }

  .ant-table.ant-table-small .ant-table-tbody > tr > td {
    padding: 0px 8px;
  }

  .ant-table.ant-table-middle .ant-table-tbody > tr > td {
    padding: 3.5px 8px;
  }

  .ant-table-column-sorters {
    display: flex;
    align-items: center;
    justify-content: unset !important;
  }

  .ant-table-column-title {
    flex: none !important;
  }

  .ant-table-column-sorter-full {
    margin-left: 8px;
  }

  @media screen and (max-width: 640px) {
    .ant-table-tbody > tr > td {
      padding-left: 24px !important;
    }
    .ant-table-thead > tr > th,
    .ant-table.ant-table-middle .ant-table-thead > tr > th {
      padding-left: 24px !important;
    }
  }

  .pending-user {
    background-color: ${theme.warn} !important;
  }
  .pending-user:hover {
    background-color: ${theme.warn} !important;
  }

  .ant-table-tbody > tr > td:last-child {
      justify-content: right !important;
  }

  ${(props) =>
    props.withBorders &&
    `
      border:  1px solid ${theme.gray300};
      border-radius: 4px;
    `}
`;

export function ExpandableTableComp<T extends object = any>(
  props: Props<T>,
): JSX.Element {
  const minHeight = `${(Number(props.scroll?.y) || 100) + 30}px`;

  return (
    <StyledTable
      dataSourceLength={props.dataSource?.length}
      minHeight={minHeight}
      headerFontSize={props.headerFontSize}
      rowSize={props.rowSize}
      fullWidth={props.fullWidth}
      withBorders={props.withBorders}
      {...props}
    />
  );
}

export default ExpandableTableComp;
