import React, { FC } from 'react';
import { B } from '../../../../components';
import { LeaveNameEnum, OrganizationLeaveI, OrganizationLeaveRequestI } from '../../../../types';
import { capitalizeUnderscore } from '../../../../util/utils';

interface Props {
  data: OrganizationLeaveRequestI ;
}

const LeaveTypeDisplay: FC<Props> = ({ data }) => {
  const leaveType = capitalizeUnderscore(data?.reqBy[0]?.leaveType.replace(/leave/gi, "").trim()); // REASON: to remove the "leave" from the leave Type
  const session = data?.reqBy[0]?.session ? capitalizeUnderscore(data?.reqBy[0]?.session) : 'Morning';

  const leaveTypeWithSession = data?.reqBy[0]?.leaveType === LeaveNameEnum.SHORT_LEAVE ? `${leaveType} - ${session}` : leaveType;
  return <B type="b-small">{leaveType ? leaveTypeWithSession: '-'}</B>;
};
export default LeaveTypeDisplay;
