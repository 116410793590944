import React, { FC, Fragment, useEffect, useState } from 'react';
import { Button, Dropdown, Menu, message, Radio, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  B,
  DeleteModal,
  Div,
  EmptyTextContainer,
  Image,
  RadioButton,
  RadioGroup,
  TableComp,
} from '../../../components';
import {
  deleteLeaveUser,
  deleteUserLeaveRequest,
  getAllOrganizationLeaves,
  getOrganizationLeaveRequests,
  leavesBalanceMe,
} from '../../../store/actions';
import { DispatchType, RootState } from '../../../store/reducer';
import theme from '../../../theme';
import {
  LeaveTypeEnum,
  OrganizationLeaveI,
  OrganizationLeaveRequestI,
  Permission,
  ReviewI,
} from '../../../types';
import { isAllowed, isManager } from '../../../util/permissionUtil';
import { reviewAllowed } from '../../../util/reviewAllowed';
import { useWindowSize } from '../../../util/screenSize';
import { addDotsForLongText } from '../../../util/utils';
import { LeaveStatus } from '../../home/homeUtil';
import LeavePeriodDisplay from '../../home/shared/leaves/LeavePeriodDisplay';
import LeaveTypeDisplay from '../../home/shared/leaves/LeaveTypeDisplay';
import { convertHoursToHoursAndMin } from '../utils';
import AdminLeave from './AdminLeave';
import LeaveRejectModal from './LeaveRejectModal';
import { ExpenseStatusTag } from '../../expenses/shared';
import { tableSorter } from '../../../util/tableSorter';
import { DataRangeI } from '../../../types/report.types';
import moment from 'moment';
import { MoreSVG } from '../../../assets';
import { ChevronUp } from '../../../assets';
import { ChevronDown } from '../../../assets';
import ExpandableTableComp from '../../../components/ExpandableTableComp';
import { LeaveStatusTags } from '../../expenses/shared/Leavestatus';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { useEditRequestReview } from '../../../api/leaveHooks';

const AdminLeaveItem = styled.div`
  @media only screen and (min-width: 769px) {
    display: none;
  }
`;

const StatusColumn = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
`;

export const ExpandButton = styled.div`
  padding: 10px;
  cursor: pointer;
  margin: -5px;
`;

export const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    border: 1px solid ${theme.blue100};
  }

  .ant-radio-button-wrapper:hover {
    border: 1px solid ${theme.blue100};
  }

  .ant-radio-button-wrapper:last-child {
    border: 1px solid ${theme.blue100};
    color: black !important;
  }

  

  .ant-radio-button-wrapper:before {
    background-color: ${theme.blue100};
  }

  .ant-radio-button-wrapper:last-child,
  .ant-radio-button-wrapper:nth-last-child(2) {
    color: ${theme.black} !important;
  }

  .ant-radio-button-wrapper:first-child {
    border: 1px solid ${theme.blue100};
    color: ${theme.blue500} !important;
  }

  display: flex;
  justify-content: flex-end;
  margin-right: 50px !important;
`;

const DropdownMenu = styled(Menu)`
  width: 163px;
  padding: 0px;
  border: 0.5px solid ${theme.gray300};
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .ant-dropdown-menu-item > a {
    padding: 12px 0px 12px 16px;
  }
  li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
    border-bottom: 1px solid ${theme.gray300};
  }
`;

interface Props {
  tableScrollY?: number;
  organizationLeaveRequests: OrganizationLeaveRequestI[];
  onDataChange: (v: ReviewI) => void;
  dateRange: DataRangeI | null;
  filter: string | null;
}

const AllLeaveTable: FC<Props> = ({
  tableScrollY,
  organizationLeaveRequests,
  onDataChange,
  dateRange,
  filter,
}) => {
  const { isDesktopLarge } = useWindowSize();
  const dispatch: DispatchType = useDispatch();
  const [visible, setVisible] = useState(false);
  const [rejectLeaveID, setRejectLeaveID] = useState<string | null>(null);
  const [rejectRequestID, setRejectRequestID] = useState<string | null>(null);
  const [status, setStatus] = useState<LeaveStatus>();

  const organizationLeaveRequestsLoading = useSelector(
    (state: RootState) => state.leave.organizationLeaveRequestsLoading,
  );
  const scrollTarget = useSelector(
    (state: RootState) => state.dashboard.scrollTarget,
  );

  const me = useSelector((state: RootState) => state.people.me);

  const {
    error: errorEditReview,
    data: editReview,
    mutate: putEditRequestReview,
  } = useEditRequestReview();

  useEffect(() => {
    dispatch(getOrganizationLeaveRequests(filter));
    dispatch(leavesBalanceMe());
  }, [editReview]);
  useEffect(() => {
    if (!errorEditReview && editReview && status) {
      if (status === LeaveStatus.ACCEPTED) {
        message.success('Leave request approved!');
      }
      if (status === LeaveStatus.REJECTED) {
        message.success('Leave request rejected!');
      }
    }
  }, [editReview, status]);

  const columns: ColumnProps<OrganizationLeaveRequestI>[] = [
    {
      title: 'Employee Name',
      dataIndex: 'name',
      ellipsis: true,
      width: 200,
      render: (_hours: string, data) => (
        <B type="b-small">
          <Tooltip title={data.reqBy[0].user?.fullName || '-'}>
            {data.reqBy[0].user?.fullName || '-'}
          </Tooltip>
        </B>
      ),
      sorter: (a, b) =>
        tableSorter.defaultSort(
          a.reqBy[0].user.fullName,
          b.reqBy[0].user.fullName,
        ),
      sortDirections: ['descend', 'ascend'],
      key: 'createdBy.fullName',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      render: (_value, data) => {
        const { pending, accepted, rejected } = data.reqBy.reduce(
          (acc, req) => {
            req.status === LeaveStatus.PENDING
              ? (acc.pending += 1)
              : req.status === LeaveStatus.ACCEPTED
              ? (acc.accepted += 1)
              : req.status === LeaveStatus.REJECTED
              ? (acc.rejected += 1)
              : null;
            return acc;
          },
          { pending: 0, accepted: 0, rejected: 0 },
        );

        const leaveStatusList = [
          { status: LeaveStatus.ACCEPTED, count: accepted },
          { status: LeaveStatus.PENDING, count: pending },
          { status: LeaveStatus.REJECTED, count: rejected },
        ];

        const hasStatus = leaveStatusList.filter(({ count }) => count > 0);

        return data.reqBy[0].reviewBy && hasStatus.length === 1 ? (
          <Tooltip
            placement="topLeft"
            title={
              <Fragment>
                {data.reqBy[0].reviewBy && (
                  <B color="white" type="b-small">
                    Reviewed By : {data.reqBy[0].reviewBy.fullName}
                  </B>
                )}
                {data.reqBy[0].reviewBy &&
                  data.reqBy[0].status === LeaveStatus.REJECTED && (
                    <B color="white" type="b-small">
                      Reject Note : {data.reqBy[0].rejectionNote || '-'}
                    </B>
                  )}
              </Fragment>
            }>
            <StatusColumn>
              <LeaveStatusTags status={data.reqBy[0].status} />
            </StatusColumn>
          </Tooltip>
        ) : (
          <StatusColumn>
            {hasStatus.length === 1 ? (
              <LeaveStatusTags status={data.reqBy[0].status} />
            ) : (
              leaveStatusList.map(
                ({ status, count }) =>
                  count > 0 && (
                    <LeaveStatusTags
                      key={status}
                      status={status}
                      count={count}
                    />
                  ),
              )
            )}
          </StatusColumn>
        );
      },
    },
    {
      title: 'Date Range / Hours',
      dataIndex: ['startDate', 'endDate'],
      width: isDesktopLarge ? 250 : 200,
      render: (_hours: string, data) => {
        const hours = !isExpandable(data) ? data?.reqBy[0]?.hours : 0;
        const range =
          data.reqBy.length > 1
            ? data.startDate + ' - ' + data.endDate
            : data.startDate + ' / ' + hours;
        return (
          <Div>
            <LeavePeriodDisplay data={range} />
          </Div>
        );
      },

      sorter: (a, b) => tableSorter.defaultSort(a.startDate, b.startDate),
      sortDirections: ['descend', 'ascend'],
      key: 'DateRange',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: isDesktopLarge ? 150 : 120,
      render: (_value, data) => <LeaveTypeDisplay data={data} />,
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      width: isDesktopLarge ? 150 : 120,
      render: (_value, data) => (
        <>
          <B type="b-small">
            {data.reqBy[0].reason ? (
              <Tooltip placement="topLeft" title={data.reqBy[0].reason}>
                {addDotsForLongText(data.reqBy[0].reason, 40)}
              </Tooltip>
            ) : (
              '-'
            )}
          </B>
        </>
      ),
    },
    {
      title: '',
      dataIndex: 'action',
      width: isDesktopLarge ? 300 : 400,
      render: (_value, data) => (
        <>
          <StyledRadioGroup>
            {!(
              isExpandable(data) &&
              data?.reqBy?.some((leave) => leave.status === LeaveStatus.REJECTED)
            ) &&
              !data.reqBy.every(
                (leave) => leave.status === LeaveStatus.ACCEPTED,
              ) &&
              reviewAllowed({
                reviewerId: me?.id,
                userId: Number(data.reqBy[0].userId),
              }) && (
                <Radio.Button>
                  {isExpandable(data) ? (
                    <B
                      type="b-small"
                      color={theme.red300}
                      cursor="pointer"
                      padding="0px"
                      onClick={() => {
                        const values = {
                          status: LeaveStatus.ACCEPTED,
                          reqId: String(data?.id),
                        };
                        setStatus(values.status);
                        putEditRequestReview(values);
                      }}>
                      Approve All
                    </B>
                  ) : (
                    <B
                      type="b-small"
                      color={theme.blue500}
                      cursor="pointer"
                      padding="0px"
                      onClick={() => {
                        const values = {
                          status: LeaveStatus.ACCEPTED,
                          leaveId: String(data?.reqBy[0].id),
                        };
                        onDataChange(values);
                      }}>
                      Approve
                    </B>
                  )}
                </Radio.Button>
              )}

            {(isAllowed(Permission.CREATE_EDIT_LEAVES_USER) ||
              (isManager() &&
                data?.reqBy?.some(
                  (leave) => leave.status === LeaveStatus.PENDING,
                ))) &&
              !(
                isExpandable(data) &&
                data?.reqBy?.some(
                  (leave) => leave.status === LeaveStatus.ACCEPTED,
                )
              ) &&
              !data.reqBy.every(
                (leave) => leave.status === LeaveStatus.REJECTED,
              ) &&
              reviewAllowed({
                reviewerId: me?.id,
                userId: Number(data.reqBy[0].userId),
              }) && (
                <Radio.Button>
                  {isExpandable(data) ? (
                    <B
                      padding="10px 0px 10px 6px"
                      type="b-small"
                      cursor="pointer"
                      onClick={() => {
                        setRejectRequestID(String(data.id));
                        setVisible(true);
                      }}>
                      Reject All
                    </B>
                  ) : (
                    <B
                      padding="10px 0px 10px 6px"
                      type="b-small"
                      cursor="pointer"
                      onClick={() => {
                        setRejectLeaveID(String(data.reqBy[0].id));
                        setVisible(true);
                      }}>
                      Reject
                    </B>
                  )}
                </Radio.Button>
              )}

            {data.reqBy.every(
              (leaves) => leaves.status === LeaveStatus.PENDING,
            ) && (
              <Radio.Button>
                <DeleteModal
                  title="Confirm Deletion"
                  buttonLabel="Yes, Delete"
                  content="Are you sure you want to delete this leave?"
                  onClickCancel={() => {
                    dispatch(
                      deleteUserLeaveRequest(
                        String(data.reqBy[0].userId),
                        String(data.reqBy[0].reqId),
                      ),
                    );
                  }}
                  openModal={
                    <B cursor="pointer" type="b-small" color="#0052ea">
                      {isExpandable(data) ? 'Cancel All' : 'Cancel'}
                    </B>
                  }
                  cancelButtonLabel="Cancel"
                />
              </Radio.Button>
            )}
          </StyledRadioGroup>
        </>
      ),
    },
  ];

  const expandedColumns: ColumnProps<OrganizationLeaveI>[] = [
    {
      title: 'Date',
      dataIndex: 'date',
      width: isDesktopLarge ? 300 : 180,
      render: (_hours: string, data) => <LeavePeriodDisplay data={data.date} />,
      sorter: (a, b) => tableSorter.defaultSort(a.date, b.date),
      sortDirections: ['descend', 'ascend'],
      key: 'date',
      defaultSortOrder: 'ascend',
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      width: isDesktopLarge ? 220 : 180,
      render: (_hours: string, data) => (
        <Div type="b-small">{convertHoursToHoursAndMin(data.hours)}</Div>
      ),
      sorter: (a, b) => tableSorter.defaultSort(a.hours, b.hours),
      sortDirections: ['descend', 'ascend'],
      key: 'hours',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: isDesktopLarge ? 320 : 200,
      render: (_value, data) =>
        data.reviewBy ? (
          <Tooltip
            placement="topLeft"
            title={
              <Fragment>
                {data.reviewBy && (
                  <B color="white" type="b-small">
                    Reviewed By : {data.reviewBy.fullName}
                  </B>
                )}
                {data.reviewBy && data.status === LeaveStatus.REJECTED && (
                  <B color="white" type="b-small">
                    Reject Note : {data.rejectionNote || '-'}
                  </B>
                )}
              </Fragment>
            }>
            <StatusColumn>
              <LeaveStatusTags status={data.status} />
            </StatusColumn>
          </Tooltip>
        ) : (
          <StatusColumn>
            <LeaveStatusTags status={data.status} />
          </StatusColumn>
        ),
    },

    {
      title: '',
      dataIndex: 'action',
      width: 120,
      render: (_value, data) => (
        <>
          <StyledRadioGroup>
            {data.status !== LeaveStatus.ACCEPTED &&
              reviewAllowed({
                reviewerId: me?.id,
                userId: Number(data.userId),
              }) && (
                <Radio.Button>
                  <B
                    type="b-small"
                    color={theme.blue500}
                    cursor="pointer"
                    padding="0px"
                    onClick={() => {
                      const values = {
                        status: LeaveStatus.ACCEPTED,
                        leaveId: String(data.id),
                      };
                      onDataChange(values);
                    }}>
                    Approve
                  </B>
                </Radio.Button>
              )}

            {(isAllowed(Permission.CREATE_EDIT_LEAVES_USER) ||
              (isManager() && data.status === LeaveStatus.PENDING)) &&
              data.status !== LeaveStatus.REJECTED &&
              reviewAllowed({
                reviewerId: me?.id,
                userId: Number(data.userId),
              }) && (
                <Radio.Button>
                  <B
                    padding="10px 0px 10px 6px"
                    type="b-small"
                    cursor="pointer"
                    onClick={() => {
                      setRejectLeaveID(String(data.id));
                      setVisible(true);
                    }}>
                    Reject
                  </B>
                </Radio.Button>
              )}
            {data.status === LeaveStatus.PENDING && (
              <Radio.Button>
                <DeleteModal
                  title="Confirm Deletion"
                  buttonLabel="Yes, Delete"
                  content="Are you sure you want to cancel this leave?"
                  onClickCancel={() => {
                    dispatch(
                      deleteLeaveUser(String(data.userId), String(data.id)),
                    );
                  }}
                  openModal={
                    <B cursor="pointer" type="b-default" color="#0052ea">
                      Cancel
                    </B>
                  }
                  cancelButtonLabel='Cancel'
                />
              </Radio.Button>
            )}
          </StyledRadioGroup>
        </>
      ),
    },
  ];

  const locale = {
    emptyText: (
      <EmptyTextContainer description="You don't have any leave requests yet" />
    ),
  };

  const startDate = moment(dateRange?.startDate).format('YYYY-MM-DD');
  const endDate = moment(dateRange?.endDate).format('YYYY-MM-DD');

  let filteredDateRange = null;
  let filteredLeaves = null;

  if (dateRange?.startDate === null || dateRange?.endDate === null) {
    filteredDateRange = organizationLeaveRequests;
  } else {
    filteredDateRange = organizationLeaveRequests.filter(
      (item) => item.startDate <= endDate && item.endDate >= startDate,
    );
  }

  const expandDataSource = (reqId: string) => {
    const filteredRequests = filteredDateRange?.filter(
      (leaveReq: OrganizationLeaveRequestI) => {
        return leaveReq.id === reqId;
      },
    );

    filteredRequests.map((leave) => (filteredLeaves = leave.reqBy));

    return filteredLeaves;
  };

  const expandedRowRender = (reqId: string) => (
    <ExpandableTableComp<OrganizationLeaveI>
      key={reqId}
      rowKey={(item) => {
        return String(item.id);
      }}
      columns={expandedColumns}
      dataSource={expandDataSource(reqId)}
      pagination={false}
    />
  );

  const isExpandable = (record: OrganizationLeaveRequestI) => {
    const count = record.reqBy.reduce(
      (acc, id) => {
        !!id ? (acc.count += 1) : null;
        return acc;
      },
      { count: 0 },
    );
    return count.count > 1;
  };

  return (
    <>
      <TableComp
        rowKey={(item) => {
          return String(item.id);
        }}
        headerFontSize={14}
        rowClassName={(row) =>
          row.id === scrollTarget ? 'yellow-highlight' : ''
        }
        withBorders={true}
        align="center"
        dataSource={filteredDateRange}
        columns={columns}
        expandable={{
          expandedRowRender: (record) => expandedRowRender(record?.id),
          rowExpandable: (record) => isExpandable(record),
          expandRowByClick: true, 
          expandIcon: ({ expanded, onExpand, record }) =>
            isExpandable(record) &&
            (expanded ? (
              <ExpandButton onClick={(e) => onExpand(record, e)}>
                <Image src={ChevronUp} space="100px" mr="6px" />
              </ExpandButton>
            ) : (
              <ExpandButton onClick={(e) => onExpand(record, e)}>
                <Image src={ChevronDown} space="100px" mr="6px" />
              </ExpandButton>
            )),
        }}
        locale={locale}
        pagination={false}
        loading={organizationLeaveRequestsLoading}
        scroll={{
          y: tableScrollY || window.innerHeight - 340,
          x: 1200,
        }}
        css={css`
          @media only screen and (max-width: 768px) {
            display: none;
          }
        `}
      />

      <AdminLeaveItem>
        {!!organizationLeaveRequests.length ? (
          <>
            {organizationLeaveRequests.map((item) => (
              <AdminLeave
                leavesDetails={item.reqBy[0]}
                onDataChange={(value) => {
                  onDataChange(value);
                }}
              />
            ))}
          </>
        ) : (
          <EmptyTextContainer description="You don't have any leave yet" />
        )}
      </AdminLeaveItem>
      {visible && (
        <LeaveRejectModal
          visible={visible}
          handleCancel={() => {
            setVisible(false);
            setRejectLeaveID(null);
          }}
          onSubmit={(values) => {
            if (rejectLeaveID && values.reasonForRejection) {
              const value = {
                status: LeaveStatus.REJECTED,
                leaveId: rejectLeaveID,
                reasonForRejection: values.reasonForRejection,
              };
              onDataChange(value);
            } else if (rejectRequestID && values.reasonForRejection) {
              const value = {
                status: LeaveStatus.REJECTED,
                reqId: rejectRequestID,
                reasonForRejection: values.reasonForRejection,
              };
              setStatus(values.status);
              putEditRequestReview(value);
            }
            setRejectLeaveID(null);
            setRejectRequestID(null);
            setVisible(false);
          }}
        />
      )}
    </>
  );
};
export default AllLeaveTable;
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
